import React from 'react'
import '../public.scss'

const PublicCard = ({ item }) => {
    return (
        <div className="Public-Card">
            <div className='Public-Card-Style'>
                <p className='Public-Card-Header'>{item.header}</p>
                <p className='Public-Card-Price'>{item.price}</p>
                <div> {item.input} </div>
                <div> {item.button} </div>

            </div>
            <div>

            </div>

        </div>
    )
}

export default PublicCard