import React from "react";
import { GetWord } from "../../../utils";
import { useFormik } from "formik";
// import validations from "../../../utils/validations";
import { MdOutlineMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { userResetPassword } from "../../../utils/firebase";

const PasswordReset = () => {
  const navigate = useNavigate();
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
      },
      onSubmit: async (values) => {
        await userResetPassword(values);
        navigate("/");
      },
      // validationSchema: validations.loginValidations,
    });

  return (
    <div className="row">
      <div className="col-12">
        <img src={process.env.PUBLIC_URL + "/Assets/images/logo.png"} />
        <h3>
          <span className="text-primary">Studia</span>
          <span className="text-secondary">{"Admin"}</span>
        </h3>
        <h4>{GetWord("IForgotMyPassword")}</h4>
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <div className="mb-4 mt-4">
              <div className="form-input">
                <label htmlFor="email">{GetWord("email")}</label>
                <div className="form-input-group">
                  <MdOutlineMail className="form-input-group-left" />
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder={GetWord("email")}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.email && touched.email && (
                  <div className="form-input-text-error">{errors.email}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-primary w-100">
              {GetWord("ResetMyPassword")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
