import baseService from "../baseService";

export default class AdminService extends baseService {
    constructor() {
        super();
        this.schemaName = "api";
    }


    getDashboardTotals() {
        return this.getObject(this.schemaName, "dashboard-totals")
    }

    getPeriodikTablo(data) {
        return this.getObject(this.schemaName, "periodic-records", "period=" + data);
    }

    getPeriodicQuestions(data) {
        return this.getObject(this.schemaName, "periodic-questions", "period=" + data)
    }

    getUserList(data) {
        return this.getObject(this.schemaName, "users", data)
    }

    getTeacherList(data) {
        return this.getObject(this.schemaName, "teachers", data)
    }

    getTeacherStats(id, data) {
        return this.getObject(this.schemaName, "teacher-stats/" + id, "period=" + data)
    }

    getWaitingTeachers(data) {
        return this.getObject(this.schemaName, "waiting-teachers", data)
    }

    getLastPurchased(data) {
        return this.getObject(this.schemaName, "last-purchased")
    }

    getUserDetail(data) {
        return this.getObject(this.schemaName, "user/" + data)
    }

    getReportedQuestions(data) {
        return this.getObject(this.schemaName, "reported-questions", data)
    }

    getReportedSolutions(data) {
        return this.getObject(this.schemaName, "reported-questions", data)
    }

    getPaymentRequests(data) {
        return this.getObject(this.schemaName, "payment-requests", data)
    }

    getAllQuestions(data) {
        return this.getObject(this.schemaName, "question/all")
    }

    getAllQuestionsClass(data) {
        return this.getObject(this.schemaName, "question/all/" + data)
    }

    getNewQuestions(data) {
        return this.getObject(this.schemaName, "question/last")
    }
    getNewQuestionsClass(data) {
        return this.getObject(this.schemaName, "question/last/" + data)
    }

    getUserReportingQuestions(data) {
        return this.getObject(this.schemaName, "student/reporting-question/" + data)
    }

    getTeacherReportingQuestions(data) {
        return this.getObject(this.schemaName, "teacher/reporting-question/" + data)
    }

    getUserReportedSolutions(data) {
        return this.getObject(this.schemaName, "student/reported-question/" + data)
    }

    getTeacherReportedSolutions(data) {
        return this.getObject(this.schemaName, "teacher/reported-question/" + data)
    }

    getQuestionsById(data) {
        return this.getObject(this.schemaName, "question/" + data)
    }

    SendNotification(data, typeNot) {
        return this.postObject(this.schemaName, "send-notification?type=" + typeNot, data)
    }

    getLastPaymentRequest(data) {
        return this.getObject(this.schemaName, "last/payment-requests")
    }

    getPurchased(data) {
        return this.getObject(this.schemaName, "purchased", data)
    }

    getPaidRequest(id, data) {
        return this.getObject(this.schemaName, "paid-requests/" + id)
    }

    putUserBlockUnblock(id, data) {
        return this.putObject(this.schemaName, "user/" + id + "/block?type=" + data)
    }

    deleteUser(id, data) {
        return this.deleteObject(this.schemaName, "user/" + id)
    }

    userUpdatePoint(id, data) {
        return this.postObject(this.schemaName, "student/update-point/" + id, data)
    }

    teacherUpdatePoint(id, data) {
        return this.postObject(this.schemaName, "teacher/update-point/" + id, data)
    }

    teacherApplication(data) {
        return this.putObject(this.schemaName, "teacher-status", data)
    }

    questionDelete(id, data) {
        return this.deleteObject(this.schemaName, "question/" + id, data)
    }


}