import React, { useCallback, useEffect, useState } from "react";
import './sellpacket.scss';
import { GetWord } from "../../../../utils";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Table } from "antd";
import Service from "../../../../Service";
import moment from "moment";
import { DatePicker } from 'antd';
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const Sellpacket = () => {
  const navigate = useNavigate();
  const [pageName, setPageName] = useOutletContext();
  const [data, setData] = useState(null);
  const [pickerData, setPickerData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const getPurchasedData = useCallback(() => {
    const service = new Service();
    const req = pickerData ? ("start_date=" + pickerData[0] + "&end_date=" + pickerData[1]) : null;
    service.AdminService.getPurchased(req).then((res) => {
      setData(res);
    })
  }, [pickerData])

  useEffect(() => {
    getPurchasedData();
  }, [getPurchasedData])

  //Genel Toplam Hesaplama
  const calculateFooter = () => {
    if (!data?.sales) return null;
    let totalAmount = 0;
    data?.sales.forEach(item => {
      totalAmount += parseFloat(item.packagePrice);
    });
    return (
      <div className="SellPacket-TotalAmount">
        <p className="SellPacket-TotalAmount-Message">Genel Tutar: {totalAmount.toFixed(2)} ₺</p>
      </div>
    );
  };


  setPageName(GetWord("Satılan Paketler"));

  const ListColumns = [
    {
      title: 'E-posta Adı',
      dataIndex: 'email',

    },
    {
      title: 'Paket Adı',
      dataIndex: 'packageName',
    },
    {
      title: 'Alış Tarihi',
      dataIndex: 'createdAt',
      render: (text) => (<span>{moment(text).format("L")}</span>)
    },
    {
      title: 'Bitiş Tarihi',
      dataIndex: 'expireDate',
      render: (text) => (<span>{moment(text).format("L")}</span>)
    },
    {
      title: 'Kalan Hak',
      dataIndex: 'remainingPoint',
    },
    {
      title: 'Ücret',
      dataIndex: 'packagePrice',
      render: (text, data) => {
        return data.packagePrice + " ₺"
      }
    },
    {
      title: 'İşlemler',
      dataIndex: '',
      render: (text, dataitem) => {

        return <button className="SellPacket-SecondArea-List-Button-DetailButton" onClick={() => navigate("/students/details/", dataitem)} style={{ cursor: 'pointer' }}>
          <span className="SellPacket-SecondArea-List-Button-DetailButton-Text">Öğrenci Detay</span ></button >
      }
    },

  ];


  const ListData = [
    {
      eMail: 'deneme@d.com',
      packetName: 'Aylık 100 Soru',
      purchaseDate: '28.12.2021',
      endDate: '28.12.2021',
      remainingRight: '32',
      price: '32₺',
      button: "",
    },
  ];

  const onChangeTable = useCallback((e) => {
    setCurrentPage(e.current - 1);
    setPageSize(e.pageSize)
  }, [])


  return (
    <div className="SellPacket">

      <div className="SellPacket">
        <div className="SellPacket-FirstArea">
          <div className="SellPacket-FirstArea-IconSelect">
            <div className='SellPacket-FirstArea-IconSelect-Icon'>
              <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path fill="currentColor" d="m0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-272h-448zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm-128-128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm-128-128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm336-332h-48v-48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-128v-48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-48c-26.5 0-48 21.5-48 48v48h448v-48c0-26.5-21.5-48-48-48z" />
              </svg>
            </div>
            <RangePicker className='SellPacket-FirstArea-IconSelect-Select CustomPlaceholder' placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']} format="DD/MM/YYYY"
              onChange={(e) => setPickerData(e ? e.map((item) => (dayjs(item).format("YYYY-MM-DD"))) : null)} />

            {/* <Select defaultValue="Option1" className='SellPacket-FirstArea-IconSelect-Select CustomPlaceholder' >
              <Option value="Option1"  >
                Tüm Zamanlar</Option>
            </Select> */}
          </div>
        </div>
      </div>
      <div className="SellPacket-SecondArea">

        <Table
          onChange={onChangeTable}
          pagination={{ total: data?.totalCount }}
          scroll={{ x: 'fit-content' }}
          columns={ListColumns}
          dataSource={data?.sales}
          footer={calculateFooter}
        />


      </div>
    </div>




  );
};
export default Sellpacket;

