import baseService from "./baseService";

export default class Banner extends baseService {
    constructor() {
        super();
        this.schemaName = "api";
    }

    getBanners(data) {
        return this.getObject(this.schemaName, "getBanners", data)
    }

    createBanner(data) {
        return this.postObject(this.schemaName, "createBanner", data)
    }
    deleteBanner(data) {
        return this.deleteObject(this.schemaName, "deleteBanner", data)
    }
}