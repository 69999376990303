import React, { useCallback, useEffect, useState } from 'react'
import { GetWord } from '../../../../../utils';
import { useOutletContext } from 'react-router-dom';
import './applications.scss';
import * as AiIcons from "react-icons/ai";
import { Select, Table } from 'antd';
import Service from '../../../../../Service';
import { toast } from 'react-toastify';

const Applications = () => {
    const [pageName, setPageName] = useOutletContext();
    const [data, setData] = useState(null);
    const [isReflesh, setIsReflesh] = useState(false);
    const [search, setsearch] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const getWaitingTeachersData = useCallback(() => {
        const service = new Service();
        service.AdminService.getWaitingTeachers("offset=" + currentPage + "&limit=" + pageSize + (search ? ("&search=" + search) : "")).then((res) => {
            setData(res);
        })
    }, [currentPage, pageSize, search])

    const teacherApplication = useCallback((type, id) => {
        const service = new Service();
        const req = {
            type: type,
            userId: id
        }
        service.AdminService.teacherApplication(req).then((res) => {
            if (res.success) {
                setIsReflesh((prev) => !prev);
                toast.success(type === 'approved' ? 'Eğitmen Onaylandı' : 'Eğitmen Reddedildi');
            }
            else {
                toast.error(res.message)
            }
        })
    }, [])



    useEffect(() => {
        getWaitingTeachersData();
    }, [getWaitingTeachersData, isReflesh, pageSize, currentPage])

    setPageName(GetWord("Eğitmen Başvuruları"));

    const ListColumns = [
        {
            title: 'İsim',
            dataIndex: 'user',
            render: (user) => `${user.name} ${user.surname}`,

        },
        {
            title: 'E-Posta Adresi',
            dataIndex: ['user', 'email'],
        },
        {
            title: 'Üniversite',
            dataIndex: ['university', 'name'],
        },
        {
            title: 'Bölüm',
            dataIndex: ['uni_department', 'name'],
        },

        {
            title: 'Öğretim Durumu',
            dataIndex: 'education',
        },

        {
            title: 'Başvurulan Dersler',
            dataIndex: '',
            render: (text, data) => {
                return data?.teacher_class.map((item, i) => {
                    return <span key={i}>
                        {item},&nbsp;
                    </span>
                })

            }
        },
        {
            title: 'Ders Kaldır',
            dataIndex: 'removeLesson',
        },
        {
            title: 'İşlem',
            dataIndex: 'button',
            render: (text, data) => {
                return <div className="ReportQuestions-Button">
                    <button className="ReportQuestions-Button-SeeQuestion" onClick={() => teacherApplication('approved', data.id)} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Onayla</span> </button>
                    <button className="ReportQuestions-Button-Delete" onClick={() => teacherApplication('delete', data.id)} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Reddet</span></button>
                </div>

            }
        },
    ];

    const onChangeTable = useCallback((e) => {
        setCurrentPage(e.current - 1);
        setPageSize(e.pageSize)
    }, [])
    return (
        <div>
            <div>
                <div className="Students-Input">
                    <input
                        onChange={(e) => setsearch(e.target.value)}
                        type="search"
                        className="Students-Input-Style"
                        placeholder={GetWord("Ara...")}
                    />
                    <span className="Students-Input-SearchLogo">
                        <AiIcons.AiOutlineSearch className="Students-Input-SearchLogo-Style" />
                    </span>
                </div>


                <div className="table">

                    <Table
                        onChange={onChangeTable}
                        pagination={{ total: data?.totalCount }}
                        columns={ListColumns}
                        dataSource={data?.data}
                        scroll={{ x: 'fit-content' }}
                    />
                </div>



            </div>
        </div>

    );

}

export default Applications