import React, { useCallback, useEffect, useState } from 'react'
import './StudentsDetails.scss';
import { Form, Input, Select, Table } from 'antd';
import { Option } from 'antd/es/mentions';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { GetWord } from '../../../../../utils';
import Service from '../../../../../Service';
import moment from 'moment';
import 'moment/locale/tr';
import { toast } from 'react-toastify';


const StudentsDetails = () => {
    moment().locale('tr');
    const [pageName, setPageName] = useOutletContext();
    setPageName(GetWord("Öğrenci Detay"));
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [inputValue, setInputValue] = useState(null);
    const userUserDetail = useCallback(() => {
        const service = new Service();
        service.AdminService.getUserDetail(id).then((res) => {
            setData(res);
        })
    }, [id])

    const userBlock = useCallback(() => {
        const service = new Service();
        service.AdminService.putUserBlockUnblock(id, "approved").then((res) => {
            toast.success('Kullanıcı başarıyla engellendi');
        }, [])
    }, [id])

    const userDelete = useCallback(() => {
        const service = new Service();
        service.AdminService.deleteUser(id).then((res) => {
            toast.success('Kullanıcı başarıyla silindi');
        })
    }, [id])

    const updatePoint = useCallback((type) => {
        if (inputValue) {
            const service = new Service();
            const req = {
                type: type,
                point: inputValue,
            }
            service.AdminService.userUpdatePoint(id, req).then((res) => {
                setInputValue(null);
                if (type === 'insert') {
                    toast.success(inputValue + ' Soru Hakkı Eklendi');
                }
                else {
                    toast.success(inputValue + ' Soru Hakkı Silindi')
                }
            })
        }
        else {
            toast.error("Soru Hakkı Boş Geçilemez")
        }
    }, [id, inputValue])

    useEffect(() => {
        userUserDetail();
    }, [userUserDetail]);



    const ListColumns = [
        {
            title: 'Paket',
            dataIndex: 'packageName',

        },
        {
            title: 'Alım Tarihi',
            dataIndex: 'purchaseDate',
            render: (text) => (<span>{moment(text).format("L")}</span>)
        },
        // {
        //     title: 'Bitiş Tarihi',
        //     dataIndex: 'endDate',
        // },

        {
            title: 'Kullanım',
            dataIndex: 'description',
        },
        {
            title: 'Ücret',
            dataIndex: 'price',
        },

    ];

    return (
        <div className='StudentsDetails'>
            <div className='StudentsDetails-FirstArea'>
                <div className='StudentsDetails-FirstArea-StudentInformation'>
                    <p className='StudentsDetails-FirstArea-StudentInformation-Text'>{data?.name + " " + data?.surname}</p>
                    <p className='StudentsDetails-FirstArea-StudentInformation-Text'>Sınıf: {data?.studentYear}</p>
                    <p className='StudentsDetails-FirstArea-StudentInformation-Text'>Sınav: {data?.exams.map((item, i) => (<span key={i}>{item} ,</span>))}</p>
                    <p className='StudentsDetails-FirstArea-StudentInformation-Text'>Toplam Soru Hakkı: 100</p>
                    <p className='StudentsDetails-FirstArea-StudentInformation-Text'>Kayıt Tarihi: {moment(data?.createdAt).format("L")} {moment(data?.createdAt).format("LTS")}</p>
                </div>

                <div className='StudentsDetails-FirstArea-ActivePackages'>
                    <div className='StudentsDetails-FirstArea-ActivePackages-Title'>Aktif Paketleri</div>
                    <div className='StudentsDetails-FirstArea-ActivePackages-Comment'>
                        <div>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left'>Paket:</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left'>Kalan Soru Hakkı:</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left'>Başlangıç Tarihi:</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left'>Bitiş Tarihi:</p>
                            <div>
                                <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left'>Otomatik Yenileme:</p>
                                <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left-Explanation'>Paket bitmesine 7 gün kaldı</p>
                            </div>
                        </div>

                        <div>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Right'>Aylık 100 Soru</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Right'>41</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Right'>20.01.2021</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Right'>19.02.2021</p>
                            <div>
                                <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Right'>Açık</p>
                                <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left-Explanation'> </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='StudentsDetails-SecondArea'>
                <div >
                    <div className='StudentsDetails-SecondArea-ButtonArea'>
                        <Form.Item >
                            <Input className='StudentsDetails-SecondArea-ButtonArea-SelectInput CustomPlaceholder' value={inputValue}
                                placeholder="Soru Hakkı Girin" onChange={(e) => setInputValue(e.target.value)}>
                            </Input>
                        </Form.Item>
                        <Select defaultValue="daily" className='StudentsDetails-SecondArea-ButtonArea-SelectInput CustomPlaceholder' >
                            <Option value="daily">Günlük</Option>
                            <Option value="weekly">Haftalık</Option>
                            <Option value="monthly">Aylık</Option>
                        </Select>
                        <button className="StudentsDetails-SecondArea-ButtonArea-Add" style={{ cursor: 'pointer' }} onClick={() => updatePoint('insert')}>
                            <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Ekle</span>
                        </button>
                    </div>

                    <div className='StudentsDetails-SecondArea-ButtonArea'>
                        <button className="StudentsDetails-SecondArea-ButtonArea-Block" style={{ cursor: 'pointer' }} onClick={userBlock}>
                            <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Bloke Et</span>
                        </button>
                        <button className="StudentsDetails-SecondArea-ButtonArea-Delete" style={{ cursor: 'pointer' }} onClick={userDelete}>
                            <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Sil</span>
                        </button>
                    </div>

                    <div className='StudentsDetails-SecondArea-ButtonArea'>
                        <Link to={"/reportquestion?userid=" + id} className="StudentsDetails-SecondArea-ButtonArea-ReportedQuestions" style={{ cursor: 'pointer' }} >
                            <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Raporlanan Soruları</span>
                        </Link>
                        <Link to={"/reportedsolutions?userid=" + id} className="StudentsDetails-SecondArea-ButtonArea-ReportedSolutions" style={{ cursor: 'pointer' }}>
                            <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Raporladığı Çözümler</span>
                        </Link>
                    </div>

                    <div className='StudentsDetails-SecondArea-ButtonArea'>
                        <Select defaultValue="Option1" className='StudentsDetails-SecondArea-ButtonArea-SelectInput CustomPlaceholder' >
                            <Option value="Option1"  >
                                Aktif Paketi Seç</Option>
                        </Select>

                        <button className="StudentsDetails-SecondArea-ButtonArea-Delete" style={{ cursor: 'pointer' }}>
                            <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Sil</span>
                        </button>

                    </div>

                    <div className='StudentsDetails-SecondArea-ButtonArea'>
                        <Form.Item >
                            <Input className='StudentsDetails-SecondArea-ButtonArea-SelectInput CustomPlaceholder' value={inputValue}
                                placeholder="Soru Hakkı Girin" onChange={(e) => setInputValue(e.target.value)}>
                            </Input>
                        </Form.Item>
                        <button className="StudentsDetails-SecondArea-ButtonArea-Delete" style={{ cursor: 'pointer' }} onClick={() => updatePoint('delete')}>
                            <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Sil</span>
                        </button>
                    </div>
                </div>

                <div className='StudentsDetails-SecondArea-History' >
                    <Table
                        columns={ListColumns}
                        dataSource={data?.purchaseHistory}
                        pagination={{
                            pageSize: 4,
                        }}
                        scroll={{ x: 'fit-content' }}
                    />
                    <p className='TableTotal'>Toplam: {data?.totalPurchasePrice}</p>


                </div>
            </div>




        </div>
    )
}

export default StudentsDetails
