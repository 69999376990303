import baseService from "../baseService";

export default class Classes extends baseService {
    constructor() {
        super();
        this.schemaName = "api";
    }
    getClass() {
        return this.getObject(this.schemaName, "classes")
    }
}

