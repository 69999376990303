import { Button, DatePicker, Form, Input, Upload } from "antd";
import { locale } from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import "./notification.scss";
import { IoNotifications } from "react-icons/io5";
import { LuCalendarDays } from "react-icons/lu";
import { TiTick } from "react-icons/ti";
import { UploadOutlined } from '@ant-design/icons';
import { setPageName } from "../../../../stores/site";
import { GetWord, randomGuid } from "../../../../utils";
import { useOutletContext } from "react-router-dom";
import Service from "../../../../Service";
import { toast } from 'react-toastify';
import { ImgUpload } from "../../../../utils/firebase";

const Notification = () => {
  const [pageName, setPageName] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  setPageName(GetWord("Bildirim Gönder"));




  const [form] = Form.useForm();

  const onFinish = useCallback((value) => {

    if (imageUrl) {
      value["img_url"] = imageUrl;
    }
    const service = new Service();
    service.AdminService.SendNotification(value, "teacher").then((res) => {
      if (res.message === "Send notification with successfully.") {
        toast.success(GetWord("Success"));
        form.resetFields();
      } else {
        toast.error(res.message);
      }
    })
  }, [form, imageUrl]
  );

  const handleChange = useCallback(async (info) => {

    if (info.file.status === 'uploading') {
      // setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      var responseUrl = await ImgUpload(
        info?.file?.originFileObj,
        "webapp/",
        randomGuid()
      );
      setImageUrl(responseUrl);
    }
  }, []);


  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toast.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  return (

    <Form onFinish={onFinish} form={form}>
      <div className='Notification'>
        <div className='Notification-Area-FormText-NotificationTitle'>
          <Form.Item
            name="title"
            label="Bildirim Başlığı"
            extra={<div><p className="Notification-Area-FormText-NotificationTitle-Explanation">Bildirim başlığı olarak gösterilir.</p></div>}
            rules={[
              {
                required: true,
                message: 'Bildirim Başlığı Boş Bırakılamaz!',
              },
            ]}>
            <Input type='text' className='Notification-Area-FormText-NotificationTitle-Input' />
          </Form.Item>

        </div>

      </div>


      <div className='Notification'>
        <div className='Notification-Area-FormText-NotificationTitle'>
          <Form.Item
            name="body"
            label="Bildirim İçeriği"
            extra={<div><p className="Notification-Area-FormText-NotificationContent-Explanation">Bildirim metni olarak gösterilir.</p></div>}
            rules={
              [
                {
                  required: true,
                  message: 'Bildirim İçeriği Boş Bırakılamaz!',
                },
              ]}>
            <Input.TextArea className='Notification-Area-FormText-NotificationContent-Input' autoSize={{
              minRows: 2,
              maxRows: 2,
            }} />
          </Form.Item>
        </div>
      </div>



      <div className='Notification'>
        <div className='Notification-Area-FormText-NotificationTitle'>
          <Form.Item
            name="notificationPicture"
            label="Bildirim Resmi"
            extra={<p className="Notification-Area-FormText-NotificationPicture-Explanation">Bir URL veya resim ekleyin.</p>}
            rules={
              [
                {
                  required: false,
                  message: 'Bildirim Başlığı Boş Bırakılamaz!',
                },
              ]}>
            <Input type='text' className='Notification-Area-FormText-NotificationPicture' />
            <Upload
              showUploadList={false}
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              beforeUpload={beforeUpload}
              onChange={handleChange}
              listType="picture-card"
              className="avatar-uploader"
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: '100%',
                  }}
                />
              ) : (
                <Button icon={<UploadOutlined />} className='Notification-Area-FormText-NotificationPicture-Button' ></Button>
              )}
            </Upload>

          </Form.Item>
        </div>
      </div>


      <div className='Notification'>
        <Form.Item className='Notification-Area-FormText-NotificationTitle'>
          <Button htmlType="submit" className='Notification-Area-FormText-SentButton'>Gönder</Button>
        </Form.Item>
      </div>


    </Form >


  );
};

export default Notification;
