import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import './newquestion.scss';
import { GetWord } from "../../../../utils";
import { Table } from "antd";
import Service from "../../../../Service";

const Newquestion = () => {
  const navigate = useNavigate();
  const [pageName, setPageName] = useOutletContext();
  const [data, setData] = useState(null);
  const getNewQuestionsData = useCallback(() => {
    const service = new Service();
    service.AdminService.getNewQuestions().then((res) => {
      setData(res);
    })
  }, [])

  useEffect(() => {
    getNewQuestionsData();
  }, [getNewQuestionsData])


  setPageName(GetWord("Yeni Sorular"));

  const ListColumns = [
    {
      title:
        <div className="NewQuestion-LessonName">Ders Adı</div>,
      dataIndex: 'className',
      render: (text, data) => {
        return <div className="NewQuestion-LessonName" > {text} </div>
      }

    },
    {
      title: 'Toplam Soru Sayısı',
      dataIndex: 'totalCount',
    },
    {
      title: 'Cevaplanmayan Soru Sayısı',
      dataIndex: 'unsolvedCount',
    },
    {
      title: 'Eğitmen Sayısı',
      dataIndex: 'teacherCount',
    },
    {
      title: 'İşlemler',
      dataIndex: 'button',
      render: (text, data) => {
        return <button className="NewQuestion-QuestionButton" onClick={() => navigate("lessons/" + data.classId)} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>
      }
    },
  ];

  const ListData = [
    {
      key: '1',
      lessonName: <div className="NewQuestion-LessonName">Türkçe</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '2',
      lessonName: <div className="NewQuestion-LessonName">Matematik</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '3',
      lessonName: <div className="NewQuestion-LessonName">Fen Bilgisi</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '4',
      lessonName: <div className="NewQuestion-LessonName">Sosyal Bilgiler</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '5',
      lessonName: <div className="NewQuestion-LessonName">Fizik</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '6',
      lessonName: <div className="NewQuestion-LessonName">Kimya</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '7',
      lessonName: <div className="NewQuestion-LessonName">Biyoloji</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '8',
      lessonName: <div className="NewQuestion-LessonName">Türk Edebiyatı</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '9',
      lessonName: <div className="NewQuestion-LessonName">Tarih</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '10',
      lessonName: <div className="NewQuestion-LessonName">Coğrafya</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },


  ];



  return (

    <div className="NewQuestion">
      <div>

        <Table
          scroll={{ x: 'fit-content' }}
          columns={ListColumns}
          dataSource={data}
        />

      </div>
    </div>

  );
};

export default Newquestion;
