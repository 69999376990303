import React, { useCallback, useEffect, useState } from "react";
import './reportquestion.scss';
import { Link, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { GetWord } from "../../../../utils";
import { Table } from "antd"
import Service from "../../../../Service";
import moment from "moment";

const Reportquestion = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userid = (searchParams.get('userid'));
  const teacherid = (searchParams.get('teacherid'));
  const [pageName, setPageName] = useOutletContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [data, setData] = useState(null);
  const getReportedQuestionsData = useCallback(() => {
    const service = new Service();
    if (userid) {
      service.AdminService.getUserReportingQuestions(userid).then((res) => {
        setData(res);
      })
    }
    else if (teacherid) {
      service.AdminService.getTeacherReportingQuestions(teacherid).then((res) => {
        setData(res);
      })
    }
    else {
      service.AdminService.getReportedQuestions("offset=" + currentPage + "&limit=" + pageSize).then((res) => {
        setData(res);
      })
    }

  }, [currentPage, pageSize, teacherid, userid])


  useEffect(() => {
    getReportedQuestionsData();
  }, [getReportedQuestionsData])



  setPageName(GetWord("Raporlanan Sorular"));

  const ListColumns = [
    {
      title: 'Adı Soyadı',
      dataIndex: '',
      render: (student) => `${student.askerName} ${student.askerSurname}`,
    },
    {
      title: 'Eğitmen Adı',
      dataIndex: '',
      render: (teacher) => `${teacher.teacherName} ${teacher.teacherSurname}`,
    },
    {
      title: 'Soru Fotoğrafı',
      dataIndex: 'image',
      render: (text, data) => <img className="ReportQuestions-QuestionPhoto" src={text} alt="" />,
    },

    {
      title: 'Rapor Sebebi',
      dataIndex: 'rejectReason',
    },
    {
      title: 'Yorum',
      dataIndex: 'status',
    },
    {
      title: 'Şık',
      dataIndex: 'answer',
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      render: (text) => (<span>{moment(text).format("L")}</span>)
    },
    {
      title: 'İşlem',
      dataIndex: 'button',
      render: (text, data) => {
        return <div className="ReportQuestions-Button">
          <button className="ReportQuestions-Button-SeeQuestion" onClick={() => navigate('/allquestion/details/' + data.id)} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Soruyu Gör</span> </button>
          <button className="ReportQuestions-Button-Delete" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Sil</span></button>
        </div>
      },
    },
  ];

  const onChangeTable = useCallback((e) => {
    setCurrentPage(e.current - 1);
    setPageSize(e.pageSize)
  }, [])
  return (

    <div>
      <div className="ReportQuestions-All">
        <div></div>

        <Link className="ReportQuestions-All-Button" to='/reportquestion' style={{ cursor: 'pointer' }}>
          <span className="ReportQuestions-All-Button-Text">Tümünü Gör</span>
        </Link>


      </div>
      <div>

        <Table
          onChange={onChangeTable}
          pagination={{ total: data?.totalCount }}
          scroll={{ x: 'fit-content' }}
          columns={ListColumns}
          dataSource={data?.data}
        />
      </div>
    </div>



  );
};

export default Reportquestion;
