import React from 'react'
import './AddSubject.scss'
import { Form, Tabs, message, Upload, Select, Input } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import CloudIcon from '../../../../../assets/images/cloud-computing.png';
import { Option } from 'antd/es/mentions';
import { useOutletContext } from 'react-router-dom';
import { GetWord } from '../../../../../utils';
import CurrentSubject from './CurrentSubject/CurrentSubject';


const AddSubject = () => {
    const [pageName, setPageName] = useOutletContext();
    setPageName(GetWord("Konu Ekle"));
    const NewAdd = () => {

        const { Dragger } = Upload;
        const props = {
            name: 'file',
            multiple: true,
            action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
            onChange(info) {
                const { status } = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            onDrop(e) {
                console.log('Dropped files', e.dataTransfer.files);
            },
        };

        return (
            <div className='AddSubject'>
                <div className='AddSubject-NewAdd'>
                    <div className="AddSubject-NewAdd-SubjectPicture">
                        <Form.Item
                            name="subjectImage"
                            label="Konu Resmi"
                            extra={<div><p className="AddSubject-NewAdd-SubjectPicture-Upload-Explanation">Konu Anlatımına ait bir resim ekleyin.</p>
                                <p className="AddSubject-NewAdd-SubjectPicture-Upload-Explanation">Özellik grafiğiniz 1.024 x 500 piksel ölçülerinde, en fazla 1 MB'lık bir PNG veya JPEG dosyası olmalıdır</p>
                            </div>}
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Banner Yazısı İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Dragger {...props} className="AddSubject-NewAdd-SubjectPicture-Upload" accept=".jpg, .jpeg, .png">
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Yüklemek için PNG veya JPEG dosyalarını buraya bırakın</p>
                                <p className="ant-upload-hint">
                                    <img src={CloudIcon} />
                                    <p className="AddSubject-NewAdd-SubjectPicture-Upload-IconText">Yükle</p>

                                </p>
                            </Dragger>
                        </Form.Item>
                    </div>

                    <div className="AddSubject-NewAdd-Lessons">
                        <Form.Item
                            name="lesson"
                            label="Ders"
                            extra={<p className="AddSubject-NewAdd-Lessons-Explanation">Konu Anlatımının dersini seçiniz</p>}
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Ders İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddSubject-NewAdd-Lessons-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>

                        </Form.Item>
                    </div>

                    <div className="AddSubject-NewAdd-Subject">
                        <Form.Item
                            name="subject"
                            label="Konu"
                            extra={<p className="AddSubject-NewAdd-Subject-Explanation">Konu Anlatımının konusunu seçiniz</p>}
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Konu İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddSubject-NewAdd-Subject-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>

                        </Form.Item>
                    </div>

                    <div className="AddSubject-NewAdd-Title">
                        <Form.Item
                            name="title"
                            label="Başlık"
                            extra={<p className="AddSubject-NewAdd-Title-Explanation">Konu Anlatımının başlığını seçiniz</p>}
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Başlık İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddSubject-NewAdd-Title-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>

                        </Form.Item>
                    </div>

                    <div className='AddSubject-NewAdd-Contents'>
                        <Form.Item
                            name="contents"
                            label="İçerik"
                            extra={<p className="AddSubject-NewAdd-Contents-Explanation">Konu anlatımının içeriğini giriniz.</p>}
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'İçerik Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Input.TextArea className='AddSubject-NewAdd-Contents-Input' showCount autoSize={{
                                minRows: 5,
                                maxRows: 5,
                            }} />

                        </Form.Item>


                    </div>

                    <div className="AddSubject-NewAdd-Type">
                        <Form.Item
                            name="type"
                            label="Tür"
                            extra={<p className="AddSubject-NewAdd-Type-Explanation">Konu Anlatımının türünü seçiniz</p>}
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Başlık İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddSubject-NewAdd-Type-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>

                        </Form.Item>
                    </div>
                    <div className='AddSubject-NewAdd-Button'>
                        <button className='AddSubject-NewAdd-Button-Style'>
                            <span className='AddSubject-NewAdd-Button-Text'>Ekle</span>
                        </button>
                    </div>
                </div>
            </div>
        )

    };




    const Available = () => {

        const CurrentSubjectData = [
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                lessonName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Konu Anlatımı",
            },
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                lessonName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Konu Anlatımı",
            },
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                lessonName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Konu Anlatımı",
            },
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                lessonName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Konu Anlatımı",
            },
        ]


        return (

            <div className='AddSubject-CurrentSubject'>

                {
                    CurrentSubjectData.map((item, i) => {

                        return <CurrentSubject item={item} key={i} index={i} />
                    }
                    )
                }
            </div>
        )
    };

    const items = [
        {
            key: '1',
            label: 'Konu Anlatımı Ekle',
            children: <NewAdd />,
        },
        {
            key: '2',
            label: 'Mevcut Konu Anlatımları',
            children: <Available />,
        },
    ];


    return (
        <div className='AddSubject'>


            <div><Tabs defaultActiveKey="1" items={items} />
            </div>
        </div>
    )
}

export default AddSubject