import { createSlice } from "@reduxjs/toolkit";
import { getLocalstorage, removeLocalstorage, setLocalstorage } from "../utils";
import { Encrypt } from "../utils/encrypted";
export const auth = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
  },
  reducers: {
    setlogin: (state, action) => {
      setLocalstorage("user", action.payload);
      state.user = action.payload;
    },

    setlogout: (state) => {
      removeLocalstorage("user");
      removeLocalstorage("tkn");
      state.user = null;
    },

    setToken: (state, action) => {
      setLocalstorage("tkn", action.payload);
      state.token = action.payload;
    },
  },
});

export const { setlogin, setlogout, setToken } = auth.actions;

export default auth.reducer;
