import React, { useCallback, useEffect, useState } from 'react'
import './Lessons.scss';
import { Table } from 'antd';
import { Navigate, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { GetWord } from '../../../../../utils';
import Service from '../../../../../Service';
import moment from 'moment';
import { toast } from 'react-toastify';

const Lessons = () => {
    const navigate = useNavigate();
    const [pageName, setPageName] = useOutletContext();
    const [data, setData] = useState([]);
    const [isReflesh, setIsReflesh] = useState(false);
    const { classId } = useParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [teacherCount, setTeacherCount] = useState(0);
    const [dataCount, setDataCount] = useState(0);



    const getAllQuestionsClassData = useCallback(() => {
        const service = new Service();
        service.AdminService.getAllQuestionsClass(classId + "&limit=" + pageSize + "&offset=" + currentPage).then((res) => {
            setData(res);
        })
    }, [classId, currentPage, pageSize])




    const DeleteQuestion = useCallback((id, userId) => {
        const service = new Service();
        const req = {
            type: "approved",
            userId: userId
        }
        service.AdminService.questionDelete(id, req).then((res) => {
            if (res.success) {
                toast.success("silindi")
                setIsReflesh((prev) => !prev)
            }
        })
    }, [])

    const getDashboardCount = useCallback(() => {
        const service = new Service();
        service.AdminService.getDashboardTotals().then((res) => {
            setTeacherCount(res.totalTeachers);
        })
    }, [])

    useEffect(() => {
        getDashboardCount();
    }, [getDashboardCount])


    useEffect(() => {
        getAllQuestionsClassData();
    }, [getAllQuestionsClassData, isReflesh, pageSize, currentPage])

    setPageName(data.length > 0 ? data[0].className : '');

    const ListColumns = [
        {
            title: 'Adı Soyadı',
            dataIndex: '',
            render: (student) => `${student.userName} ${student.userSurname}`,
        },
        {
            title: 'Eğitmen Adı',
            dataIndex: '',
            render: (student) => `${student.teacherName} ${student.teacherSurname}`,
        },
        {
            title: 'Soru Fotoğrafı',
            dataIndex: 'image',
            render: (text, data) => <img className="Lessons-QuestionPhoto" src={text} alt="" />,

        },
        {
            title: 'Cevap Fotoğrafı',
            dataIndex: 'image',
            render: (text, data) => <img className="Lessons-QuestionPhoto" src={text} alt="" />,
        },

        {
            title: 'Puan',
            dataIndex: 'point',
        },
        {
            title: 'Yorum',
            dataIndex: 'answer',
        },
        {
            title: 'Şık',
            dataIndex: 'option',
        },
        {
            title: 'Tarih',
            dataIndex: 'createdAt',
            render: (text) => (<span>{moment(text).format("L")}</span>)
        },
        {
            title: 'Cevaplandı mı?',
            dataIndex: 'answered',
        },
        {
            title: 'İşlem',
            dataIndex: 'button',
            render: (text, data) => {
                return <div className="Lessons-Button">
                    <div className="Lessons-Button-DetailDelete" >
                        <button className="Lessons-Button-DetailDelete-Detail" onClick={() => navigate('/allquestion/details/' + data.id)} style={{ cursor: 'pointer' }}><span className="Lessons-Button-DetailDelete-Text">Detay</span> </button>
                        <button className="Lessons-Button-DetailDelete-Delete" onClick={() => DeleteQuestion(data.id, data.userId)} style={{ cursor: 'pointer' }}><span className="Lessons-Button-DetailDelete-Text">Sil</span></button> </div>
                </div>
            }
        },
    ];
    const onChangeTable = useCallback((e) => {
        setCurrentPage(e.current - 1);
        setPageSize(e.pageSize)
    }, [])

    return (
        <div className='Lessons'>
            <div className='Lessons-List'>

                <Table
                    onChange={onChangeTable}
                    pagination={{ total: setDataCount }}
                    columns={ListColumns}
                    dataSource={data}
                    scroll={{ x: 'fit-content' }}
                />


            </div>


        </div>
    )
}

export default Lessons