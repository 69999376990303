import React, { useCallback, useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import './QuestionsDetails.scss';
import { GetWord } from '../../../../../utils';
import Service from '../../../../../Service';



const QuestionsDetails = () => {
    const [pageName, setPageName] = useOutletContext();
    const [data, setData] = useState(null);
    const { questionId } = useParams();
    const getQuestionsByIdData = useCallback(() => {
        const service = new Service();
        service.AdminService.getQuestionsById(questionId).then((res) => {
            setData(res);
        })
    }, [questionId])

    useEffect(() => {
        getQuestionsByIdData();
    }, [getQuestionsByIdData])

    setPageName(GetWord("Soru Detayı"));


    return (
        <div>

            <div className='QuestionsDetails-FirstArea'>
                <div className='QuestionsDetails-FirstArea-Style'>
                    <p className='QuestionsDetails-FirstArea-Text'>Mehmet ALi KARA</p>
                    <p className='QuestionsDetails-FirstArea-Text'>mehmetalikara42@gmail.com</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Aktif Aboneliği: Aylık 100 Soru</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Soruyu Cevaplayan Eğitmen: Ahmet</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Puan: {data?.point}</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Soru Hakkı: 84</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Şık: {data?.answerOption}</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Yorum: Hızlı Çözün</p>
                </div>
                <div className='QuestionsDetails-FirstArea-Annotation'>
                    <p className='QuestionsDetails-FirstArea-Annotation-AnnotationTitle'>Ek Açıklama:</p>
                    <p className='QuestionsDetails-FirstArea-Annotation-MessageTitle'>Öğrenci:</p>
                    <p className='QuestionsDetails-FirstArea-Annotation-Message'>Sorudan hirbir şey anlamadım</p>
                    <p className='QuestionsDetails-FirstArea-Annotation-MessageTitle'>Eğitmen:</p>
                    <p className='QuestionsDetails-FirstArea-Annotation-Message'>Soruda xleri çekip y yerine yazdık</p>

                </div>


            </div>
            <div className='QuestionsDetails-SecondArea'>
                <div>
                    <p className='QuestionsDetails-SecondArea-Text'>Soru:</p>
                    <img src={data?.image} className='QuestionsDetails-SecondArea-Question' alt='' />
                </div>
                <div>
                    <p className='QuestionsDetails-SecondArea-Text'>Cevap:</p>
                    <img src={data?.answerImage} className='QuestionsDetails-SecondArea-Question' alt='' />
                </div>

            </div>


        </div>
    )
}

export default QuestionsDetails
