import Organisations from "./Institutional/Organisations";
import Visa from "./auth";
import Profiles from "./Profiles";
import Banner from "./Banner";
import Student from "./Institutional/Student";
import Classes from "./Institutional/Classes";
import AdminService from "./Admin/AdminService";

export default class Service {
  visa = new Visa();
  Organisations = new Organisations();
  Student = new Student();
  Profiles = new Profiles();
  Banner = new Banner();
  Classes = new Classes();
  AdminService = new AdminService();
}
