import React from "react";
import './public.scss'
import PublicCard from "./PublicCard/PublicCard";
import { useOutletContext } from "react-router-dom";
import { GetWord } from "../../../../utils";
import { Input } from "antd";

const Public = () => {

  const [pageName, setPageName] = useOutletContext();
  setPageName(GetWord("Genel Ayarlar"));




  const PublicCardData = [
    {
      header: "1 Yıldız Ücreti",
      price: "0.20₺",
      input: <Input className='Public-Card-Input' placeholder="Ücret..." />,
      button: <button className='Public-Card-Button-Style'>
        <span className='Public-Card-Button-Text'>Güncelle</span>
      </button>,
    },
    {
      header: "2 Yıldız Ücreti",
      price: "0.25₺",
      input: <Input className='Public-Card-Input' placeholder="Ücret..." />,
      button: <button className='Public-Card-Button-Style'>
        <span className='Public-Card-Button-Text'>Güncelle</span>
      </button>,
    },
    {
      header: "3 Yıldız Ücreti",
      price: "0.40₺",
      input: <Input className='Public-Card-Input' placeholder="Ücret..." />,
      button: <button className='Public-Card-Button-Style'>
        <span className='Public-Card-Button-Text'>Güncelle</span>
      </button>,
    },
    {
      header: "4 Yıldız Ücreti",
      price: "0.35₺",
      input: <Input className='Public-Card-Input' placeholder="Ücret..." />,
      button: <button className='Public-Card-Button-Style'>
        <span className='Public-Card-Button-Text'>Güncelle</span>
      </button>,
    },
    {
      header: "5 Yıldız Ücreti",
      price: "0.40₺",
      input: <Input className='Public-Card-Input' placeholder="Ücret..." />,
      button: <button className='Public-Card-Button-Style'>
        <span className='Public-Card-Button-Text'>Güncelle</span>
      </button>,
    },
    {
      header: "Puanlanmış Soru Ücreti",
      price: "0.30₺",
      input: <Input className='Public-Card-Input' placeholder="Ücret..." />,
      button: <button className='Public-Card-Button-Style'>
        <span className='Public-Card-Button-Text'>Güncelle</span>
      </button>,
    },
    {
      header: "1 Saatteki Soru Sayısı",
      price: "30",
      input: <Input className='Public-Card-Input' placeholder="Soru Sayısı Giriniz..." />,
      button: <button className='Public-Card-Button-Style'>
        <span className='Public-Card-Button-Text'>Güncelle</span>
      </button>,
    },
    {
      header: "Eğitmen Minimum Bakiye",
      price: "80₺",
      input: <Input className='Public-Card-Input' placeholder="Bakiye Giriniz..." />,
      button: <button className='Public-Card-Button-Style'>
        <span className='Public-Card-Button-Text'>Güncelle</span>
      </button>,
    },
    {
      header: "İlk Kayıtta Hediye Hak",
      price: "3",
      input: <Input className='Public-Card-Input' placeholder="Bakiye Giriniz..." />,
      button: <button className='Public-Card-Button-Style'>
        <span className='Public-Card-Button-Text'>Güncelle</span>
      </button>,
    },


  ]

  return (


    <div className="Public">
      {
        PublicCardData.map((item, i) => {

          return <PublicCard item={item} key={i} index={i} />
        }
        )
      }

    </div>


  );
};

export default Public;
