import React from "react";
import './sidebar.scss';
import { FaUserAlt } from "react-icons/fa";
import { GiGraduateCap } from "react-icons/gi";
import { RxDashboard } from 'react-icons/rx';
import { IoIosNotificationsOutline, IoMdPaper } from "react-icons/io";
import { MdOutlineDashboard, MdOutlineSpaceDashboard } from "react-icons/md";
import { TbClipboardText, TbClipboardX, TbWallet } from "react-icons/tb";
import { useSelector } from "react-redux";
import SidebarItem from "./SideBarItem";


const data = [
  {
    id: 1,
    isHeader: true,
    title: "Ana Menü",
  },
  {
    id: 2,
    isHeader: false,
    title: "Dashboard",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon" transform="scale(-1, 1)">
      <g fill="currentColor" >
        <path d="m2 6.5c0-2.12132 0-3.18198.65901-3.84099s1.71967-.65901 3.84099-.65901 3.18198 0 3.841.65901c.659.65901.659 1.71967.659 3.84099s0 3.18198-.659 3.841c-.65902.659-1.71968.659-3.841.659s-3.18198 0-3.84099-.659c-.65901-.65902-.65901-1.71968-.65901-3.841z" opacity=".5" />
        <path d="m13 17.5c0-2.1213 0-3.182.659-3.841s1.7197-.659 3.841-.659 3.182 0 3.841.659.659 1.7197.659 3.841 0 3.182-.659 3.841-1.7197.659-3.841.659-3.182 0-3.841-.659-.659-1.7197-.659-3.841z" opacity=".5" />
        <path d="m2 17.5c0-2.1213 0-3.182.65901-3.841s1.71967-.659 3.84099-.659 3.18198 0 3.841.659c.659.659.659 1.7197.659 3.841s0 3.182-.659 3.841c-.65902.659-1.71968.659-3.841.659s-3.18198 0-3.84099-.659-.65901-1.7197-.65901-3.841z" />
        <path d="m13 6.5c0-2.12132 0-3.18198.659-3.84099s1.7197-.65901 3.841-.65901 3.182 0 3.841.65901.659 1.71967.659 3.84099 0 3.18198-.659 3.841c-.659.659-1.7197.659-3.841.659s-3.182 0-3.841-.659c-.659-.65902-.659-1.71968-.659-3.841z" />
      </g>
    </svg >,
    pathname: "/",
  },
  {
    id: 3,
    isHeader: false,
    title: "Öğrenciler",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon" transform="scale(-1, 1)">
      <g fill="currentColor">
        <path d="m14.2172 3.49965c-1.421-.6662-3.0135-.6662-4.43448 0l-6.69112 3.13694c-1.076.50446-1.35653 1.92693-.8416 2.91007v4.95334c0 .4142.33579.75.75.75s.75-.3358.75-.75v-3.828l6.03281 2.8283c1.42099.6662 3.01349.6662 4.43449 0l6.6911-3.1369c1.4555-.68235 1.4555-3.04441 0-3.72676z" />
        <path d="m5 11.2581 4.78281 2.2422c1.42099.6662 3.01349.6662 4.43449 0l4.7827-2.2422v5.3671c0 1.0081-.5035 1.9518-1.3853 2.4402-1.4684.8132-3.8187 1.9344-5.6147 1.9344s-4.1463-1.1212-5.61467-1.9344c-.88183-.4884-1.38533-1.4321-1.38533-2.4402z" opacity=".5" />
      </g></svg>,
    pathname: "/students",
  },
  {
    id: 4,
    isHeader: false,
    title: "Eğitmenler",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon">
      <g fill="#currentColor"><circle cx="12" cy="6" r="4" />
        <ellipse cx="12" cy="17" opacity=".5" rx="7" ry="4" /></g></svg>,
    // icon: <FaUserAlt className="nav-icon" />,
    pathname: "/teachers ",
  },
  {
    id: 5,
    isHeader: true,
    title: "Sorular",
  },
  {
    id: 6,
    isHeader: false,
    title: "Yeni Sorular",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon" >
      <g fill="currentColor">
        <path d="m21 15.9983v-6.00004c0-2.82843 0-4.24264-.8787-5.12132-.7684-.76838-1.9463-.86483-4.1213-.87694h-8c-2.17503.01211-3.35294.10856-4.12132.87694-.87868.87868-.87868 2.29289-.87868 5.12132v6.00004c0 2.8284 0 4.2426.87868 5.1213s2.29289.8787 5.12132.8787h6c2.8284 0 4.2426 0 5.1213-.8787s.8787-2.2929.8787-5.1213z" opacity=".5" />
        <path d="m8 3.5c0-.82843.67157-1.5 1.5-1.5h5c.8284 0 1.5.67157 1.5 1.5v1c0 .82843-.6716 1.5-1.5 1.5h-5c-.82843 0-1.5-.67157-1.5-1.5z" />
        <path clip-rule="evenodd" d="m6.25 14.5c0-.4142.33579-.75.75-.75h8c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-8c-.41421 0-.75-.3358-.75-.75zm0 3.5c0-.4142.33579-.75.75-.75h5.5c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-5.5c-.41421 0-.75-.3358-.75-.75z" fill-rule="evenodd" />
      </g></svg>,
    pathname: "/newquestion ",
  },
  {
    id: 7,
    isHeader: false,
    title: "Tüm Sorular",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon">
      <g fill="currentColor">
        <path d="m20 8.25v9.75c0 3-1.79 4-4 4h-8c-2.21 0-4-1-4-4v-9.75c0-3.25 1.79-4 4-4 0 .62.24997 1.18.65997 1.59s.97003.66 1.59003.66h3.5c1.24 0 2.25-1.01 2.25-2.25 2.21 0 4 .75 4 4z" opacity=".4" />
        <path d="m16 4.25c0 1.24-1.01 2.25-2.25 2.25h-3.5c-.62 0-1.18003-.25-1.59003-.66s-.65997-.97-.65997-1.59c0-1.24 1.01-2.25 2.25-2.25h3.5c.62 0 1.18.25 1.59.66s.66.97.66 1.59z" />
        <path d="m12 13.75h-4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75z" />
        <path d="m16 17.75h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75z" />
      </g></svg>,
    pathname: "/allquestion ",
  },
  {
    id: 8,
    isHeader: false,
    title: "Raporlanan Sorular",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon">
      <g fill="currentColor">
        <path d="m21 15.9983v-6.00004c0-2.82843 0-4.24264-.8787-5.12132-.7684-.76838-1.9463-.86483-4.1213-.87694h-8c-2.17503.01211-3.35294.10856-4.12132.87694-.87868.87868-.87868 2.29289-.87868 5.12132v6.00004c0 2.8284 0 4.2426.87868 5.1213s2.29289.8787 5.12132.8787h6c2.8284 0 4.2426 0 5.1213-.8787s.8787-2.2929.8787-5.1213z" opacity=".5" />
        <path d="m8 3.5c0-.82843.67157-1.5 1.5-1.5h5c.8284 0 1.5.67157 1.5 1.5v1c0 .82843-.6716 1.5-1.5 1.5h-5c-.82843 0-1.5-.67157-1.5-1.5z" />
        <path clip-rule="evenodd" d="m8.96967 10.4697c.29289-.2929.76777-.2929 1.06063 0l1.9697 1.9697 1.9697-1.9697c.2929-.2929.7677-.2929 1.0606 0s.2929.7678 0 1.0607l-1.9696 1.9696 1.9696 1.9696c.2929.2929.2929.7678 0 1.0607s-.7678.2929-1.0607 0l-1.9696-1.9696-1.9696 1.9696c-.29294.2929-.76782.2929-1.06071 0s-.29289-.7677 0-1.0606l1.96961-1.9697-1.96963-1.9697c-.29289-.2929-.29289-.7677 0-1.0606z" fill-rule="evenodd" />
      </g></svg>,
    pathname: "/reportquestion ",
  },
  {
    id: 9,
    isHeader: false,
    title: "Raporlanan Çözümler",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon">
      <g fill="currentColor">
        <path d="m21 15.9983v-6.00004c0-2.82843 0-4.24264-.8787-5.12132-.7684-.76838-1.9463-.86483-4.1213-.87694h-8c-2.17503.01211-3.35294.10856-4.12132.87694-.87868.87868-.87868 2.29289-.87868 5.12132v6.00004c0 2.8284 0 4.2426.87868 5.1213s2.29289.8787 5.12132.8787h6c2.8284 0 4.2426 0 5.1213-.8787s.8787-2.2929.8787-5.1213z" opacity=".5" />
        <path d="m8 3.5c0-.82843.67157-1.5 1.5-1.5h5c.8284 0 1.5.67157 1.5 1.5v1c0 .82843-.6716 1.5-1.5 1.5h-5c-.82843 0-1.5-.67157-1.5-1.5z" />
        <path clip-rule="evenodd" d="m8.96967 10.4697c.29289-.2929.76777-.2929 1.06063 0l1.9697 1.9697 1.9697-1.9697c.2929-.2929.7677-.2929 1.0606 0s.2929.7678 0 1.0607l-1.9696 1.9696 1.9696 1.9696c.2929.2929.2929.7678 0 1.0607s-.7678.2929-1.0607 0l-1.9696-1.9696-1.9696 1.9696c-.29294.2929-.76782.2929-1.06071 0s-.29289-.7677 0-1.0606l1.96961-1.9697-1.96963-1.9697c-.29289-.2929-.29289-.7677 0-1.0606z" fill-rule="evenodd" />
      </g></svg>,
    pathname: "/reportedsolutions",
  },
  {
    id: 10,
    isHeader: true,
    title: "Finans",
  },
  {
    id: 11,
    isHeader: false,
    title: "Satılan Paketler",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon">
      <g fill="currentColor">
        <path d="m5 15c-2.21 0-4 1.79-4 4 0 .75.21 1.46.58 2.06.69 1.16 1.96 1.94 3.42 1.94s2.73-.78 3.42-1.94c.37-.6.58-1.31.58-2.06 0-2.21-1.79-4-4-4zm1.97 3.67-2.13 1.97c-.14.13-.33.2-.51.2-.19 0-.38-.07-.53-.22l-.99-.99c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l.48.48 1.6-1.48c.3-.28.78-.26 1.06.04s.26.78-.04 1.06z" />
        <path d="m17.7495 7.04997c-.24-.04-.49-.04999-.75-.04999h-10.00005c-.28 0-.55.02-.81.06.14-.28.34-.53997.58-.77997l3.25005-3.26001c1.37-1.36 3.59-1.36 4.96 0l1.75 1.76996c.64.63.98 1.43001 1.02 2.26001z" />
        <path d="m22 12v5c0 3-2 5-5 5h-9.37c.31-.26.58-.58.79-.94.37-.6.58-1.31.58-2.06 0-2.21-1.79-4-4-4-1.2 0-2.27.53-3 1.36v-4.36c0-2.72 1.64-4.62 4.19-4.94.26-.04.53-.06.81-.06h10c.26 0 .51.00999.75.04999 2.58.3 4.25 2.21001 4.25 4.95001z" opacity=".4" />
        <path d="m22 12.5h-3c-1.1 0-2 .9-2 2s.9 2 2 2h3" />
      </g></svg>,
    pathname: "/sellpacket ",
  },
  {
    id: 12,
    isHeader: false,
    title: "Ödeme Talepleri",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon">
      <g fill="currentColor">
        <path d="m17.7495 7.04997c-.24-.04-.49-.04999-.75-.04999h-10.00005c-.28 0-.55.02-.81.06.14-.28.34-.53997.58-.77997l3.25005-3.26001c1.37-1.36 3.59-1.36 4.96 0l1.75 1.76996c.64.63.98 1.43001 1.02 2.26001z" />
        <path d="m5 15c-2.21 0-4 1.79-4 4 0 .75.21 1.46.58 2.06.69 1.16 1.96 1.94 3.42 1.94s2.73-.78 3.42-1.94c.37-.6.58-1.31.58-2.06 0-2.21-1.79-4-4-4zm1.49 4.73h-.74v.78c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-.78h-.74c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h.74v-.71c0-.41.34-.75.75-.75s.75.34.75.75v.71h.74c.41 0 .75.34.75.75s-.33.75-.75.75z" />
        <path d="m22 12v5c0 3-2 5-5 5h-9.37c.31-.26.58-.58.79-.94.37-.6.58-1.31.58-2.06 0-2.21-1.79-4-4-4-1.2 0-2.27.53-3 1.36v-4.36c0-2.72 1.64-4.62 4.19-4.94.26-.04.53-.06.81-.06h10c.26 0 .51.00999.75.04999 2.58.3 4.25 2.21001 4.25 4.95001z" opacity=".4" />
        <path d="m22 12.5h-3c-1.1 0-2 .9-2 2s.9 2 2 2h3" />
      </g></svg>,
    pathname: "/PaymentRequests",
  },
  {
    id: 13,
    isHeader: true,
    title: "Uygulama",
  },
  {
    id: 14,
    isHeader: false,
    title: "Bildirim Gönder",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon">
      <g fill="currentColor">
        <path d="m18.7491 9v.7041c0 .845.2412 1.6711.6931 2.3741l1.1074 1.723c1.0116 1.5737.2394 3.7127-1.52 4.2104-4.6023 1.3018-9.45686 1.3018-14.05924 0-1.75931-.4977-2.53154-2.6367-1.52-4.2104l1.10744-1.723c.45192-.703.69307-1.5291.69307-2.3741v-.7041c0-3.86599 3.02169-7 6.74913-7 3.7274 0 6.7491 3.13401 6.7491 7z" opacity=".5" />
        <path d="m12.75 6c0-.41421-.3358-.75-.75-.75s-.75.33579-.75.75v4c0 .4142.3358.75.75.75s.75-.3358.75-.75z" />
        <path d="m7.24316 18.5449c.65094 2.0052 2.53451 3.4548 4.75664 3.4548 2.2222 0 4.1057-1.4496 4.7567-3.4548-3.1455.5903-6.3679.5903-9.51334 0z" />
      </g></svg>,
    pathname: "/notification ",
  },
  {
    id: 15,
    isHeader: false,
    title: "Banner/Story Ekle",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon">
      <g fill="currentColor">
        <path d="m6 8c0-2.82843 0-4.24264.87868-5.12132s2.29289-.87868 5.12132-.87868c2.8284 0 4.2426 0 5.1213.87868s.8787 2.29289.8787 5.12132v8c0 2.8284 0 4.2426-.8787 5.1213s-2.2929.8787-5.1213.8787c-2.82843 0-4.24264 0-5.12132-.8787s-.87868-2.2929-.87868-5.1213z" />
        <g opacity=".5">
          <path d="m6.14114 4.5c-.14114.84287-.14114 1.96249-.14114 3.5v8c0 1.5375 0 2.6571.14114 3.5h-.14114c-1.40013 0-2.1002 0-2.63498-.2725-.4704-.2397-.85285-.6221-1.09254-1.0925-.27248-.5348-.27248-1.2349-.27248-2.635v-7c0-1.40013 0-2.1002.27248-2.63498.23969-.4704.62214-.85285 1.09254-1.09254.53478-.27248 1.23485-.27248 2.63498-.27248z" />
          <path d="m17.8589 4.5c.1411.84287.1411 1.96248.1411 3.49998v8.00002c0 1.5375 0 2.6571-.1411 3.5h.1411c1.4001 0 2.1002 0 2.635-.2725.4704-.2397.8528-.6221 1.0925-1.0925.2725-.5348.2725-1.2349.2725-2.635v-7c0-1.40013 0-2.1002-.2725-2.63498-.2397-.4704-.6221-.85285-1.0925-1.09254-.5348-.27248-1.2349-.27248-2.635-.27248z" />
        </g></g></svg>,
    pathname: "/story ",
  },
  {
    id: 16,
    isHeader: false,
    title: "Test/Konu/Bilgi Kartı Ekle",
    icon: <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" className="nav-icon">
      <g fill="currentColor">
        <path clip-rule="evenodd" d="m20.8293 10.7154-.5177 1.9319c-.6042 2.2551-.9064 3.3826-1.5913 4.1139-.5408.5774-1.2407.9815-2.0111 1.1611-.0963.0225-.194.0398-.2942.0521-.9151.1129-2.0316-.1863-4.0642-.7309-2.2551-.6043-3.38265-.9064-4.11393-1.5913-.57742-.5408-.9815-1.2407-1.16114-2.0112-.22752-.9758.0746-2.1033.67885-4.35837l.51764-1.93186c.08688-.32423.16757-.62537.24399-.90516.45507-1.66604.76088-2.58263 1.3473-3.20874.54079-.57742 1.24069-.9815 2.01119-1.16114.9757-.22752 2.1033.0746 4.3584.67885 2.255.60425 3.3826.90637 4.1139 1.59129.5774.54081.9815 1.2407 1.1611 2.01116.2275.97577-.0746 2.10331-.6788 4.35837zm-9.7769-.90951c.1072-.4001.5185-.63754.9186-.53033l4.8296 1.29414c.4001.1072.6375.5184.5303.9185s-.5184.6375-.9185.5303l-4.8297-1.2941c-.4001-.1072-.6375-.5184-.5303-.91851zm-.7768 2.89741c.1072-.4001.5184-.6375.9185-.5303l2.8978.7765c.4001.1072.6375.5184.5303.9185s-.5184.6376-.9185.5304l-2.8978-.7765c-.4001-.1072-.6376-.5185-.5303-.9186z" fill-rule="evenodd" />
        <path d="m16.4149 17.9745c-.2085.6383-.5751 1.2158-1.0679 1.6774-.7313.6849-1.8589.987-4.1139 1.5913-2.25512.6042-3.38266.9064-4.35844.6788-.77045-.1796-1.47034-.5837-2.01115-1.1611-.68492-.7313-.98704-1.8588-1.59129-4.1139l-.51764-1.9318c-.60425-2.2551-.90637-3.3827-.67885-4.3584.17964-.7705.58372-1.47039 1.16114-2.0112.73128-.68492 1.85882-.98704 4.1139-1.59129.42663-.11431.81292-.21782 1.16544-.30897.00003-.0001-.00003.00011 0 0-.07642.27979-.15711.58123-.24399.90547l-.51764 1.93185c-.60425 2.25504-.90637 3.38264-.67885 4.35844.17964.7704.58372 1.4703 1.16114 2.0111.73128.6849 1.85883.9871 4.11393 1.5913 2.0325.5446 3.1491.8438 4.0641.731z" opacity=".5" />
      </g></svg>,
    pathname: "/TestSubject ",
  },
  {
    id: 17,
    isHeader: false,
    title: "Genel",
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="nav-icon">
      <g transform="translate(-50 -1018)">
        <g fill="currentColor">
          <path data-name="Vector" d="M8.075.52A1.748 1.748 0 0 0 6.835 0h-5.08a1.74 1.74 0 0 0-1.49.83 1.7 1.7 0 0 0-.09 1.66 10.841 10.841 0 0 0 6.09 5.43 1.847 1.847 0 0 0 .57.1 1.763 1.763 0 0 0 1.75-1.76l.01-4.5a1.791 1.791 0 0 0-.52-1.24z" transform="translate(52.165 1032.22)" />
          <path data-name="Vector" d="M21 8.35A10.685 10.685 0 0 0 10.525 0 10.685 10.685 0 0 0 .045 8.35a1.7 1.7 0 0 0 .34 1.47 1.764 1.764 0 0 0 1.38.66H19.3a1.764 1.764 0 0 0 1.38-.66A1.735 1.735 0 0 0 21 8.35z" transform="translate(51.475 1019.25)" />
          <path data-name="Vector" d="M8.31.85a1.754 1.754 0 0 0-1.5-.84L1.75 0A1.741 1.741 0 0 0 0 1.75l.01 4.48a1.763 1.763 0 0 0 1.75 1.76 1.54 1.54 0 0 0 .56-.1 10.851 10.851 0 0 0 6.07-5.38A1.721 1.721 0 0 0 8.31.85z" transform="translate(63.25 1032.25)" />
        </g>
      </g>
    </svg>
    ,
    pathname: "/public ",
  },
];

const Sidebar = () => {
  const { sideBtn } = useSelector((state) => state.site);
  return (
    <aside className={"aside " + (sideBtn ? "active" : "")}>
      <nav>
        {data.map((item, i) => {
          return (
            <SidebarItem
              pathname={item.pathname}
              icon={item.icon}
              title={item.title}
              isHeader={item.isHeader}
              key={i}
            />
          );
        })}
        {/* <div className='nav-header '>
      aaa
    </div> */}
      </nav>
    </aside>
  );
};

export default Sidebar;
