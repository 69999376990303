import React from 'react'
import '../AddTest.scss'

const CurrentTest = ({ item, index }) => {


    return (
        <div className="AddTest-CurrentTest">
            < div className="AddTest-CurrentTest-Card">
                <img src={item.image} className='AddTest-CurrentTest-Image' />
                <div className='AddTest-CurrentTest-LessonDate' >
                    <div className='AddTest-CurrentTest-LessonName'>{item.lessonName}</div>
                    <div className='AddTest-CurrentTest-Date'>
                        <div className='AddTest-CurrentTest-Date-Icon' >{item.dateIcon}</div>
                        <div className='AddTest-CurrentTest-Date-Text' >{item.date}</div>
                    </div>
                </div>

                <div className='AddTest-CurrentTest-Subject' >{item.subject}</div>

                <div>
                    <div className='AddTest-CurrentTest-Section'>
                        <p className='AddTest-CurrentTest-Section-Text' >{item.section}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentTest