import baseService from "../baseService";

export default class Student extends baseService {
    constructor() {
        super();
        this.schemaNameOrganisations = "api/organisations";
        this.schemaName = "api";
    }

    /*
     * kurumsal sayfada user Listesini Çeker
     */
    students() {
        return this.getObject(this.schemaNameOrganisations, "users")
    }

    /**
     * 
     * @param {string} id User idsi
     * öğrencilerin detaylarını çeker. adı soyadı vb.
     */
    studentProfile(id) {
        return this.getObject(this.schemaName, "userProfile", "userId=" + id)
    }

    /**
     * 
     * @param {*} UserId UserIdasi verilmesi 
     * @param {*} StartDate başlangıç tarihi orn 2023-09-01
     * @param {*} EndDate bitis Tarihi orn 2023-09-02
     * 
     * öğrencinin aylık soru dağılımını gösterir
     */
    studentQuestionMonthStatics(UserId, StartDate, EndDate) {
        return this.getObject(this.schemaNameOrganisations, "user-statistics/mounthlyCount/" + UserId, "start_date=" + StartDate + "&end_date=" + EndDate)
    }

    /**
     * 
     * @param {*} UserId user idsi
     * @param {*} ClassId Ders Idsi orn 1(fen bilgisi)
     * @param {*} StartDate başlangıç tarihi orn 2023-09-01
     * @param {*} EndDate bitis Tarihi orn 2023-09-02
     * öğrencinin derslere göre en çok soru sorduğu konular.
     */
    studentQuestionTopicStatics(UserId, ClassId, StartDate, EndDate) {
        return this.getObject(this.schemaNameOrganisations, "user-statistics/getMostQuestionTopics/" + UserId + "/" + ClassId, "start_date=" + StartDate + "&end_date=" + EndDate)
    }


    /**
     * 
     * @param {*} UserId 
     * @returns haftalık soru dağılımını verir orneğin maaatesi 30 tane
     * haftalık soru dağılımını verir orneğin maaatesi 30 tane
     */
    studentQuestionLastWeekStatics(UserId) {
        return this.getObject(this.schemaNameOrganisations, "user-statistics/lastDayCount/" + UserId)
    }

    /**
 * 
 * @param {*} UserId UserIdasi verilmesi 
 * @param {*} StartDate başlangıç tarihi orn 2023-09-01
 * @param {*} EndDate bitis Tarihi orn 2023-09-02
 * 
 * öğrencinin Derslere göre soru dağılımını gösterir
 */
    studentQuestionClassStatics(UserId, StartDate, EndDate) {
        return this.getObject(this.schemaNameOrganisations, "user-statistics/classCount/" + UserId, "start_date=" + StartDate + "&end_date=" + EndDate)
    }


}