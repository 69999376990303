import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setSideBtn } from "../../../../stores/site";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";
import * as TbIcons from "react-icons/tb";
import * as GiIcons from "react-icons/gi";
import * as MdIcons from "react-icons/md";
import { GetWord } from "../../../../utils";
import { setlogout } from "../../../../stores/auth";
import { async } from "@firebase/util";
import { signoutUser } from "../../../../utils/firebase";
import './header.scss';
import { TiThMenu } from "react-icons/ti";

const Header = ({ pageName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = async () => {
    await signoutUser();
    dispatch(setlogout());
    navigate("/auth");
  };

  return (
    <header className="header">
      <div className="header-left ">
        <div className="d-flex justify-content-between alignt">
          <div className="Logo">
            <span>
              <img src={process.env.PUBLIC_URL + "/Assets/images/logo.png"} alt="logo" /> Studia
            </span>

          </div>
          <div className="text-white mt-auto mb-auto">
            <button
              className="NavBar"
              onClick={() => dispatch(setSideBtn())}
            >
              <TiThMenu />
              {/* <GiIcons.GiHamburgerMenu /> */}
            </button>
          </div>
        </div>
      </div>
      <div className="header-center d-none d-md-inline">
        <p>{pageName}</p>
      </div>
      <div className="header-right">

        <div className="header-Input">
          <input
            type="search"
            className="header-Input-Style"
            placeholder={GetWord("Ara...")}
          />
          <span className="header-Input-SearchLogo">
            <AiIcons.AiOutlineSearch className="header-Input-SearchLogo-Style" />
          </span>
        </div>

        <a className="icon">
          <MdIcons.MdNotifications />
        </a>
        <a className="icon cursor-pointer" onClick={() => logoutHandler()}>
          <img src={process.env.PUBLIC_URL + "/Assets/images/exitbutton.svg"} alt="çıkış" />
          <span className="exiticon" >Çıkış Yap</span>
        </a>
      </div>
    </header >
  );
};

export default Header;
