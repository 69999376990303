import React, { useCallback, useEffect, useState } from "react";
import "./students.scss";
import { Link, useFetcher, useNavigate, useOutletContext } from "react-router-dom";
import { GetWord } from "../../../../utils";
import * as AiIcons from "react-icons/ai";
import { Checkbox, Input, Table } from "antd";
import Service from "../../../../Service";
import moment from "moment";

const Students = () => {
  const [pageName, setPageName] = useOutletContext();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  setPageName(GetWord("Öğrenciler"));

  const [data, setData] = useState(null);
  const [stundetCount, setStudentCount] = useState(0);
  const [search, setsearch] = useState(null);

  const getUserListData = useCallback(() => {
    const service = new Service();
    service.AdminService.getUserList("offset=" + currentPage + "&limit=" + pageSize + (search ? ("&search=" + search) : "")).then((res) => {
      setData(res);

    })
  }, [currentPage, pageSize, search])

  const getDashboardCount = useCallback(() => {
    const service = new Service();
    service.AdminService.getDashboardTotals().then((res) => {
      setStudentCount(res.totalStudents);
    })
  }, [])

  useEffect(() => {
    getDashboardCount();
  }, [getDashboardCount])

  useEffect(() => {
    getUserListData();
  }, [getUserListData, pageSize, currentPage]);

  const ListColumns = [
    {
      title: 'ID',
      dataIndex: 'ID',

    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdAt',
      render: (text) => (<span>{moment(text).format("L")}</span>)
    },
    {
      title: '',
      dataIndex: 'photo',
    },

    {
      title: 'İsim',
      dataIndex: 'name',
    },
    {
      title: 'E-Posta Adresi',
      dataIndex: 'email',
    },
    {
      title: 'Soru Hakkı',
      dataIndex: 'questionCount',
    },
    {
      title: 'İşlem',
      dataIndex: 'button',
      render: (text, data) => {
        return <button className="Students-DetailButton" onClick={() => navigate("details/" + data.id)} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>
      },
    },
  ];
  const onChangeTable = useCallback((e) => {
    setCurrentPage(e.current - 1);
    setPageSize(e.pageSize)
  }, [])

  return (

    <div className="Students">
      <div className="Students-Input">
        <Input
          onChange={(e) => setsearch(e.target.value)}
          type="search"
          className="Students-Input-Style"
          placeholder={GetWord("Ara...")}
        />
        <span className="Students-Input-SearchLogo">
          <AiIcons.AiOutlineSearch className="Students-Input-SearchLogo-Style" />
        </span>
      </div>


      <div className="table">
        <Table
          onChange={onChangeTable}
          pagination={{ total: data?.totalCount }}
          scroll={{ x: 'fit-content' }}
          columns={ListColumns}
          dataSource={data?.data}
        />
      </div>
    </div>

  );
};

export default Students;
