import React from 'react'
import './JobDetail.scss';
import { GetWord } from '../../../../../utils';
import { useOutletContext } from 'react-router-dom';

const JobDetail = () => {

    const [pageName, setPageName] = useOutletContext();
    setPageName(GetWord("İş Detayı"));

    return (
        <div className='JobDetail'>
            <div className='JobDetail-FirstArea-Style'>
                <p className='JobDetail-FirstArea-Heading'>İş Detayı</p>
                <div className='JobDetail-FirstArea-Line'>
                    <p className='JobDetail-FirstArea-Line-Title'>İş:</p>
                    <p className='JobDetail-FirstArea-Line-Text'>Konu Anlatımı</p>
                </div>
                <div className='JobDetail-FirstArea-Line'>
                    <p className='JobDetail-FirstArea-Line-Title'>Ücret:</p>
                    <p className='JobDetail-FirstArea-Line-Text'>5₺</p>
                </div>
                <div className='JobDetail-FirstArea-Line'>
                    <p className='JobDetail-FirstArea-Line-Title'>Ders:</p>
                    <p className='JobDetail-FirstArea-Line-Text'>Matematik</p>
                </div>
                <div className='JobDetail-FirstArea-Line'>
                    <p className='JobDetail-FirstArea-Line-Title'>Doğal Sayılar:</p>
                    <p className='JobDetail-FirstArea-Line-Text'>Matematik</p>
                </div>
                <div className='JobDetail-FirstArea-Line'>
                    <p className='JobDetail-FirstArea-Line-Title'>Eğitmen:</p>
                    <p className='JobDetail-FirstArea-Line-Text'>Ahmet Yılmaz</p>
                </div>

            </div>

            <div className='JobDetail-SecondArea-Style'>
                <p className='JobDetail-SecondArea-Heading'>İş İçeriği</p>
                <div className='JobDetail-SecondArea-Line'>
                    <p className='JobDetail-SecondArea-Line-Title'>Başlık:</p>
                    <p className='JobDetail-SecondArea-Line-Text'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut</p>
                </div>
                <div className='JobDetail-SecondArea-Line'>
                    <p className='JobDetail-SecondArea-Line-Text'>
                        <span className='JobDetail-SecondArea-Line-Title'>Konu Anlatımı:</span>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                        est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet</p>
                </div>


            </div>

            <div className='JobDetail-ButtonArea'>

                <button className="JobDetail-ButtonArea-Button-Approve">
                    <span className="JobDetail-ButtonArea-Button-Approve-Text">Onayla</span>
                </button>
                <button className="JobDetail-ButtonArea-Button-Reject">
                    <span className="JobDetail-ButtonArea-Button-Reject-Text">Reddet</span>
                </button>

            </div>
        </div >
    )
}

export default JobDetail