import React from 'react'
import '../dashboard.scss'

const LeftAreaCard = ({ item, index }) => {
    return (
        <div className={"Dashboard-LeftArea-DashboardCard-Card-" + (index + 1)} >
            <div className={"Dashboard-LeftArea-DashboardCard-Card-" + (index + 1) + "-Icon"}>{item.icon}</div>
            <p className={"Dashboard-LeftArea-DashboardCard-Card-" + (index + 1) + "-Number"}>{item.number}</p>
            <p className={"Dashboard-LeftArea-DashboardCard-Card-" + (index + 1) + "-BigTitle"}>{item.bigTitle}</p>
            <p className={"Dashboard-LeftArea-DashboardCard-Card-" + (index + 1) + "-Explanation"}>{item.explanation}</p>
        </div>
    )
}

export default LeftAreaCard