import React from 'react'
import '../AddCard.scss'

const CurrentCard = ({ item, index }) => {
    return (
        <div className="AddCard-CurrentCard">
            < div className="AddCard-CurrentCard-Card">
                <img src={item.image} className='AddCard-CurrentCard-Image' />
                <div className='AddCard-CurrentCard-CardNameDate' >
                    <div className='AddCard-CurrentCard-CardName'>{item.cardName}</div>
                    <div className='AddCard-CurrentCard-Date'>
                        <div className='AddCard-CurrentCard-Date-Icon' >{item.dateIcon}</div>
                        <div className='AddCard-CurrentCard-Date-Text' >{item.date}</div>
                    </div>
                </div>

                <div className='AddCard-CurrentCard-Subject' >{item.subject}</div>

                <div>
                    <div className='AddCard-CurrentCard-Section'>
                        <p className='AddCard-CurrentCard-Section-Text' >{item.section}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentCard