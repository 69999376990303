import baseService from "./baseService";

export default class Visa extends baseService {
  constructor() {
    super();
    this.schemaName = "Visa";
  }

  getMyProfile() {
    return this.getObject("api", "userProfile");
  }
}
