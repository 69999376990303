import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input, Select, Table } from 'antd'
import './TeachersDetails.scss'
import { Link, useOutletContext, useParams } from 'react-router-dom'
import { GetWord } from '../../../../../utils'
import { Option } from 'antd/es/mentions'
import { ImCross } from "react-icons/im";
import Service from '../../../../../Service'
import moment from 'moment'
import { toast } from 'react-toastify'


const TeachersDetails = () => {
    const [pageName, setPageName] = useOutletContext();
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [inputValue, setInputValue] = useState(null);
    const [paidRequestData, setPaidRequestData] = useState(null);
    const [statsCheck, setStatsCheck] = useState(true);

    const getTeacherStatsData = useCallback(() => {
        const service = new Service();
        service.AdminService.getTeacherStats(id, statsCheck).then((res) => {
            setData(res);
        })
    }, [id, statsCheck])

    const getPaidRequestData = useCallback(() => {
        const service = new Service();
        service.AdminService.getPaidRequest(id).then((res) => {
            setPaidRequestData(res);
        })
    }, [id])

    const updatePoint = useCallback((type) => {
        if (inputValue) {
            const service = new Service();
            const req = {
                type: type,
                point: inputValue,
            }
            service.AdminService.teacherUpdatePoint(id, req).then((res) => {
                setInputValue(null);

                if (type === 'insert') {
                    toast.success(inputValue + '₺ Bakiye Eklendi')
                }
                else {
                    toast.success(inputValue + '₺ Bakiye Silindi')
                }

            })
        }
        else {
            toast.error('Bakiye Boş Geçilemez.')
        }
    }, [id, inputValue])

    useEffect(() => {
        getTeacherStatsData();
        getPaidRequestData();
    }, [getPaidRequestData, getTeacherStatsData])

    setPageName(GetWord("Eğitmen Detay"));

    const PaymentColumns = [
        {
            title: 'Tarih',
            dataIndex: 'createdAt',
            render: (text) => (<span>{moment(text).format("L")}</span>)
        },
        {
            title: 'İsim',
            dataIndex: '',
            render: (text, data) => {
                return <p> {text?.name} {text?.surname}</p>
            }
        },
        {
            title: 'Banka',
            dataIndex: 'bankName',
        },
        {
            title: 'IBAN Numarası',
            dataIndex: 'iban',
        },
        {
            title: 'Mevcut Bakiye',
            dataIndex: 'currentBalance',
        },
        {
            title: 'Kalan Bakiye',
            dataIndex: 'remainingBalance',
        },
        {
            title: 'Tutar',
            dataIndex: 'amount',
            render: (text, data) => {
                return <p> {text} ₺</p>
            }
        },
    ];
    const PaymentData = [
        {
            date: '19.05.2021',
            name: 'Mehmet Ali KARA',
            bank: 'Ziraat Bankası',
            iban: 'TR76000990134567800100001',
            currentBalance: '106',
            remainingBalance: '10',
            amount: '96.40₺',
        },

    ];
    return (
        <div className='TeachersDetails'>
            <div className='TeachersDetails-FirstArea'>
                <div className='TeachersDetails-FirstArea-PersonalInformation'>
                    <p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Title'>Kişisel Bilgiler</p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Text'>{data?.name + " " + data?.surname}</p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Text'>{data?.email}</p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Text'>{data?.phone}</p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Text'>Kayıt Tarihi: {moment(data?.createdAt).format("L")} {moment(data?.createdAt).format("LTS")}</p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Text'>Dersler: {data?.classes.map((item, i) => {
                            return <span key={i}>
                                {item},&nbsp;
                            </span>
                        })}</p>
                    </p>
                </div>
                <div className='TeachersDetails-FirstArea-Balance'>
                    <p className='TeachersDetails-FirstArea-Balance-Title'>Bakiye Ekle</p>
                    <Form.Item>
                        <Input className='TeachersDetails-FirstArea-Balance-Input CustomPlaceholder' placeholder="Bakiye Giriniz..."
                            value={inputValue} onChange={(e) => setInputValue(e.target.value)}>
                        </Input>
                    </Form.Item>
                    <div className="TeachersDetails-FirstArea-Balance-Button" >
                        <button className="TeachersDetails-FirstArea-Balance-Button-Add-Style" style={{ cursor: 'pointer' }} onClick={() => updatePoint('insert')}>
                            <span className="TeachersDetails-FirstArea-Balance-Button-Add-Text">Ekle</span>
                        </button>
                        <button className="TeachersDetails-FirstArea-Balance-Button-Delete-Style" style={{ cursor: 'pointer' }} onClick={() => updatePoint('delete')}>
                            <span className="TeachersDetails-FirstArea-Balance-Button-Delete-Text">Sil</span>
                        </button>
                    </div>
                </div>
                <div className='TeachersDetails-FirstArea-Report'>
                    <Link to={"/reportquestion?teacherid=" + id} className="TeachersDetails-FirstArea-Report-ReportedQuestions" style={{ cursor: 'pointer' }}>
                        <span className="TeachersDetails-FirstArea-Report-ReportedQuestions-Text">Raporladığı Sorular</span>
                    </Link>
                    <Link to={"/reportedsolutions?teacherid=" + id} className="TeachersDetails-FirstArea-Report-ReportedSolutions" style={{ cursor: 'pointer' }}>
                        <span className="TeachersDetails-FirstArea-Report-ReportedSolutions-Text">Raporlanan Çözümleri</span>
                    </Link>
                </div>

                <div className='TeachersDetails-FirstArea-BlockDelete'>

                    <button className="TeachersDetails-FirstArea-BlockDelete-Block" style={{ cursor: 'pointer' }}>
                        <ImCross className="TeachersDetails-FirstArea-BlockDelete-Block-Icon" />
                        <span className="TeachersDetails-FirstArea-BlockDelete-Block-Text"> Bloke Et</span>
                    </button>

                    <button className="TeachersDetails-FirstArea-BlockDelete-Delete" style={{ cursor: 'pointer' }}>
                        <ImCross className="TeachersDetails-FirstArea-BlockDelete-Delete-Icon" />
                        <span className="TeachersDetails-FirstArea-BlockDelete-Delete-Text">Sil</span>
                    </button>
                </div>
            </div>
            <div className='TeachersDetails-SecondArea'>
                <div className='TeachersDetails-SecondArea-Left'>
                    <div className='TeachersDetails-SecondArea-Left-ButtonArea'>
                        <Select defaultValue="Option1" className='TeachersDetails-SecondArea-Left-ButtonArea-Select CustomPlaceholder' >
                            <Option value="Option1"  >
                                Ders Seç</Option>
                        </Select>

                        <button className="TeachersDetails-SecondArea-Left-ButtonArea-Delete" style={{ cursor: 'pointer' }}>
                            <span className="TeachersDetails-SecondArea-Left-ButtonArea-ButtonText">Sil</span>
                        </button>

                        <Select defaultValue="Option1" className='TeachersDetails-SecondArea-Left-ButtonArea-Select CustomPlaceholder' >
                            <Option value="Option1"  >
                                Ders Seç</Option>
                        </Select>

                        <button className="TeachersDetails-SecondArea-Left-ButtonArea-Add" style={{ cursor: 'pointer' }}>
                            <span className="TeachersDetails-SecondArea-Left-ButtonArea-ButtonText">Ekle</span>
                        </button>
                    </div>
                    <div className='TeachersDetails-SecondArea-Left-Payments' >
                        <p className='TeachersDetails-SecondArea-Left-Payments-Title'>Ödemeler</p>
                        <Table
                            columns={PaymentColumns}
                            // dataSource={paidRequestData}
                            pagination={false}
                            scroll={{ x: 'fit-content' }}

                        />
                    </div>
                    <div className='TeachersDetails-SecondArea-Left-WithdrawalRequest'>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Title' >Mevcut Para Çekme Talebi</span>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Text' >{paidRequestData?.name}  {paidRequestData?.surname}</span>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Text' >{paidRequestData?.bankName}</span>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Text' >{paidRequestData?.iban}</span>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Text' >{paidRequestData?.amount} ₺</span>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Text' >{moment(paidRequestData?.createdAt).format("LLL")}</span>
                    </div>
                </div>

                <div className='TeachersDetails-SecondArea-Right-Stats'>
                    <div className='TeachersDetails-SecondArea-Right-Stats-TitleSelect'>
                        <span className='TeachersDetails-SecondArea-Right-Stats-TitleSelect-Title'>İstatistikler</span>
                        <div className='TeachersDetails-SecondArea-Right-Stats-TitleSelect-IconSelect'>
                            <div className='TeachersDetails-SecondArea-Right-Stats-TitleSelect-IconSelect-Icon'>
                                <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-272h-448zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm-128-128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm-128-128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm336-332h-48v-48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-128v-48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-48c-26.5 0-48 21.5-48 48v48h448v-48c0-26.5-21.5-48-48-48z" />
                                </svg>
                            </div>
                            <Select onChange={(value) => setStatsCheck(value)} defaultValue="weekly" className='TeachersDetails-SecondArea-Right-Stats-TitleSelect-Select ' >
                                <Option value="weekly"  >Haftalık</Option>
                                <Option value="monthly"  >Aylık</Option>
                                <Option value="all"  >Tümü</Option>
                            </Select>
                        </div>
                    </div>
                    <div className='TeachersDetails-SecondArea-Right-Stats-Area'>
                        <div className='TeachersDetails-SecondArea-Right-Stats-Area-Left'>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Toplam Kazanç</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Alınan Ödemeler</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Mevcut Bakiye</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Toplam Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Cevaplanan Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Reddilen Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Ortalama Cevap Süresi</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Değerlendirilen Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Değerlendirne Bekleyen Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Değerlendirilmeyen Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Ortalama Puan</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Mesaj Yazılan Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Zaman Aşımına Uğrayan Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Raporlanan Soru</p>
                        </div>

                        <div className='TeachersDetails-SecondArea-Right-Stats-Area-Right'>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>890,90₺</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>800₺</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>90₺</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>{data?.stats?.totalNoOfQuestions}</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>{data?.stats?.totalNoOfAnswered}</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>{data?.stats?.totalNoOfRejected}</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>{data?.stats?.averageSolutionTime} dk</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>{data?.stats?.totalNoOfStarred}</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>{data?.stats?.totalNoOfSolved}</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>{data?.stats?.totalNoOfNotStarred}</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>4,2</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>64</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>64</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>48</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeachersDetails
