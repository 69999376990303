import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import './allquestion.scss';
import { GetWord } from "../../../../utils";
import { Table } from "antd";
import Service from "../../../../Service";

const Allquestion = () => {
  const navigate = useNavigate();
  const [pageName, setPageName] = useOutletContext();
  const [data, setData] = useState(null);
  const getAllQuestionsData = useCallback(() => {
    const service = new Service();
    service.AdminService.getAllQuestions().then((res) => {
      setData(res);
    })
  }, [])

  useEffect(() => {
    getAllQuestionsData();
  }, [getAllQuestionsData])

  setPageName(GetWord("Tüm Sorular"));

  const ListColumns = [
    {
      title:
        <div className="AllQuestion-LessonName">Ders Adı</div>,
      dataIndex: 'className',
      render: (text, data) => {
        return <div className="AllQuestion-LessonName" > {text} </div>
      }
    },
    {
      title: 'Toplam Soru Sayısı',
      dataIndex: 'totalCount',
    },
    {
      title: 'Cevaplanmayan Soru Sayısı',
      dataIndex: 'unsolvedCount',
    },
    {
      title: 'Eğitmen Sayısı',
      dataIndex: 'teacherCount',
    },
    {
      title: 'İşlemler',
      dataIndex: 'button',
      render: (text, data) => {
        return <button className="AllQuestion-QuestionButton" onClick={() => navigate("lessons/" + data.classId)} style={{ cursor: 'pointer' }}><span className="AllQuestion-QuestionButton-Text">Sorular</span></button>
      }
    },
  ];

  return (
    <div className="AllQuestion">
      <div>

        <Table
          scroll={{ x: 'fit-content' }}
          columns={ListColumns}
          dataSource={data}
        />

      </div>
    </div>

  );
};

export default Allquestion;
