import React, { useCallback, useEffect, useState } from 'react'
import './Lessons.scss';
import { Table } from 'antd';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { GetWord } from '../../../../../utils';
import Service from '../../../../../Service';
import moment from 'moment';

const Lessons = () => {
    const navigate = useNavigate();
    const [pageName, setPageName] = useOutletContext();
    const [data, setData] = useState(null);
    const { classId } = useParams();


    const getNewQuestionsClassData = useCallback(() => {
        const service = new Service();
        service.AdminService.getNewQuestionsClass(classId).then((res) => {
            setData(res);
        })
    }, [classId])

    useEffect(() => {
        getNewQuestionsClassData();
    }, [getNewQuestionsClassData])

    setPageName(GetWord("Matematik"));

    const ListColumns = [
        {
            title: 'Adı Soyadı',
            dataIndex: '',
            render: (student) => `${student.userName} ${student.userSurname}`,
        },
        {
            title: 'Eğitmen Adı',
            dataIndex: '',
            render: (student) => `${student.teacherName} ${student.teacherSurname}`,
        },
        {
            title: 'Soru Fotoğrafı',
            dataIndex: 'questionPhoto',
        },
        {
            title: 'Cevap Fotoğrafı',
            dataIndex: 'solitionPhoto',
        },

        {
            title: 'Puan',
            dataIndex: 'point',
        },
        {
            title: 'Yorum',
            dataIndex: 'answer',
        },
        {
            title: 'Şık',
            dataIndex: 'option',
        },
        {
            title: 'Tarih',
            dataIndex: 'createdAt',
            render: (text) => (<span>{moment(text).format("L")}</span>)
        },
        {
            title: 'Cevaplandı mı?',
            dataIndex: 'answered',
        },
        {
            title: 'İşlem',
            dataIndex: 'button',
            render: (text, data) => {
                return <div className="Lessons-Button">
                    <div className="Lessons-Button-DetailDelete" >
                        <button className="Lessons-Button-DetailDelete-Detail" onClick={() => navigate('/newquestion/details/' + data.id)} style={{ cursor: 'pointer' }}><span className="Lessons-Button-DetailDelete-Text">Detay</span> </button>
                        <button className="Lessons-Button-DetailDelete-Delete" onClick={<Lessons />} style={{ cursor: 'pointer' }}><span className="Lessons-Button-DetailDelete-Text">Sil</span></button> </div>
                </div>
            }
        },
    ];

    return (
        <div className='Lessons'>
            <div className='Lessons-List'>
                <Table
                    columns={ListColumns}
                    dataSource={data}
                    scroll={{ x: 'fit-content' }}
                />
            </div>
        </div>
    )
}

export default Lessons