import React from 'react'
import '../AddSubject.scss'

const CurrentSubject = ({ item, index }) => {
    return (
        <div>
            < div className="AddSubject-CurrentSubject-Card">
                <img src={item.image} className='AddSubject-CurrentSubject-Image' />
                <div className='AddSubject-CurrentSubject-LessonDate'>
                    <div className='AddSubject-CurrentSubject-LessonName'>{item.lessonName}</div>
                    <div className='AddSubject-CurrentSubject-Date'>
                        <div className='AddSubject-CurrentSubject-Date-Icon' >{item.dateIcon}</div>
                        <div className='AddSubject-CurrentSubject-Date-Text' >{item.date}</div>
                    </div>
                </div>

                <div className='AddSubject-CurrentSubject-Subject' >{item.subject}</div>

                <div>
                    <div className='AddSubject-CurrentSubject-Section'>
                        <p className='AddSubject-CurrentSubject-Section-Text' >{item.section}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentSubject