import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Service from "../../../../Service";
import './teachers.scss';
import { GetWord } from "../../../../utils";
import { Checkbox, Table } from "antd";
import * as AiIcons from "react-icons/ai";
import moment from "moment";



const Teachers = () => {
  const [pageName, setPageName] = useOutletContext();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [search, setsearch] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [teacherCount, setTeacherCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  const getTeachersListData = useCallback(() => {
    const service = new Service();
    service.AdminService.getTeacherList("offset=" + currentPage + "&limit=" + pageSize + (search ? ("&search=" + search) : "")).then((res) => {
      console.log(res);

      setData(res);
    })
  }, [currentPage, pageSize, search])


  const getWaitingTeachersData = useCallback(() => {
    const service = new Service();
    service.AdminService.getWaitingTeachers().then((res) => {
      setDataCount(res.totalCount);
    })
  }, [])


  const getDashboardCount = useCallback(() => {
    const service = new Service();
    service.AdminService.getDashboardTotals().then((res) => {
      setTeacherCount(res.totalTeachers);
    })
  }, [])

  useEffect(() => {
    getDashboardCount();
  }, [getDashboardCount])

  useEffect(() => {
    getTeachersListData();
    getWaitingTeachersData();

  }, [getTeachersListData, getWaitingTeachersData, pageSize, currentPage])

  setPageName(GetWord("Eğitmenler"));


  const ListColumns = [
    {
      title: 'ID',
      dataIndex: 'ID',

    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: ['user', 'createdAt'],
      render: (text) => (<span>{moment(text).format("L")}</span>)
    },
    {
      title: 'İsim',
      dataIndex: 'user',
      render: (user) => `${user.name} ${user.surname}`,
    },
    {
      title: 'E-Posta Adresi',
      dataIndex: ['user', 'email'],
    },
    {
      title: 'Hesap Bakiyesi',
      dataIndex: 'earnedPoints',
    },
    {
      title: 'İşlem',
      dataIndex: 'button',
      render: (text, data) => {
        return <button className="Students-DetailButton" onClick={() => navigate("details/" + data.id)} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>
      }
    },
  ];

  const onChangeTable = useCallback((e) => {
    setCurrentPage(e.current - 1);
    setPageSize(e.pageSize)
  }, [])

  return (
    <div className="Teachers" >
      <div className="Teachers-Application">
        <div className="Teachers-Input">
          <input
            onChange={(e) => setsearch(e.target.value)}
            type="search"
            className="Teachers-Input-Style"
            placeholder={GetWord("Ara...")}
          />
          <span className="Teachers-Input-SearchLogo">
            <AiIcons.AiOutlineSearch className="Teachers-Input-SearchLogo-Style" />
          </span>
        </div>
        <Link to="/teachers/applications">
          <button className="Teachers-Application-Button" style={{ cursor: 'pointer' }} href="/teachers/applications">
            <span className="Teachers-Application-Button-Text">Başvurular</span><span className="Teachers-Application-Button-Text-Number">{dataCount}</span>
          </button>
        </Link>

      </div>
      <div>
        <div className="table">
          <Table
            onChange={onChangeTable}
            pagination={{ total: data?.totalCount }}
            scroll={{ x: 'fit-content' }}
            columns={ListColumns}
            dataSource={data?.data}
          />
        </div>
      </div>
    </div>
  );
};

export default Teachers;
