import React, { useCallback, useEffect, useMemo, useState } from "react";
import './dashboard.scss'
import LeftAreaCard from "./LeftAreaCard/LeftAreaCard.jsx";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../../stores/site.js";
import { Pie, Line } from '@ant-design/plots';
import { Table } from "antd";
import { Link } from "react-router-dom";
import Service from "../../../../Service/index.js";
import { FaRegCircle, FaRegCircleCheck } from "react-icons/fa6";
import { GetWord } from "../../../../utils/index.js";



const Dashboard = () => {
  const [data, setData] = useState(null);
  const [teacherStundetCheckData, setTeacherStundetCheckData] = useState(null);
  const [teacherStundetCheck, setTeacherStundetCheck] = useState(true);
  const [questinosCheckData, setQuestionsCheckData] = useState(null);
  const [questionsCheck, setQuestionsCheck] = useState(true);
  const [lastPaymentRequestData, setLastPaymentRequestData] = useState(null);
  const [purchasedData, setPurchasedData] = useState(null);



  const dispatch = useDispatch();
  dispatch(setPageName("Dashboard"))


  const getDashboardCount = useCallback(() => {
    const service = new Service();
    service.AdminService.getDashboardTotals().then((res) => {
      setData(res);
    })
  }, [])

  const getPeriodikTabloData = useCallback(() => {
    const service = new Service();
    service.AdminService.getPeriodikTablo(!teacherStundetCheck ? "monthly" : "weekly").then((res) => {
      if (res && res.dailyStats.length > 0) {
        const Teacher = res?.dailyStats.map((item, i) => ({
          date: item.date,
          value: parseInt(item.teacherCount),
          category: GetWord("Teacher"),
          key: i
        }));
        const Stundent = res?.dailyStats.map((item, i) => ({
          date: item.date,
          value: parseInt(item.studentCount),
          category: GetWord("Student"),
          key: i
        }))

        setTeacherStundetCheckData({ dataList: [...Teacher, ...Stundent], previousStudent: res.previousStudent, previousTeacher: res.previousTeacher });
      }
    })
  }, [teacherStundetCheck])

  const getQuestionsCheckData = useCallback(() => {
    const service = new Service();
    service.AdminService.getPeriodicQuestions(!questionsCheck ? "monthly" : "weekly").then((res) => {
      setQuestionsCheckData(res)
    })
  }, [questionsCheck])

  const getLastPaymentRequestData = useCallback(() => {
    const service = new Service();
    service.AdminService.getLastPaymentRequest().then((res) => {
      setLastPaymentRequestData(res?.data);
    }, [])
  }, [])

  const getPurchasedData = useCallback(() => {
    const service = new Service();
    service.AdminService.getPurchased().then((res) => {
      setPurchasedData(res?.sales);
    }, [])
  }, [])



  useEffect(() => {
    getDashboardCount();
    getPeriodikTabloData();
    getQuestionsCheckData();
    getLastPaymentRequestData();
    getPurchasedData();
  }, [getDashboardCount, getLastPaymentRequestData, getPeriodikTabloData, getPurchasedData, getQuestionsCheckData])


  const CardData = [
    {

      icon: <img src={process.env.PUBLIC_URL + "/Assets/images/TotalTeachersIcon.svg"} />,
      number: data?.totalTeachers,
      bigTitle: "Toplam Eğitmen",
      explanation: "+" + data?.todayTeachers + " bugün",
    },
    {
      icon: <img src={process.env.PUBLIC_URL + "/Assets/images/TotalStudentsIcon.svg"} />,
      number: data?.totalStudents,
      bigTitle: "Toplam Öğrenci",
      explanation: "+" + data?.todayUsers + " bugün",
    },
    {
      icon: < img src={process.env.PUBLIC_URL + "/Assets/images/TotalQuestionsIcon.svg"} />,
      number: data?.waitingQuestion,
      bigTitle: "Bekleyen Soru",
    },
    {
      icon: < img src={process.env.PUBLIC_URL + "/Assets/images/TotalQuestionsIcon.svg"} />,
      number: data?.totalQuestion,
      bigTitle: "Toplam Soru",
    },
  ]





  const InactiveStudentComp = useMemo(() => {
    var totalStudents = ((data?.inactiveStudent * 100) / data?.totalStudents).toFixed(0);

    const InactiveStudentsData = [
      {
        type: 'İnaktif Öğrenciler',
        value: data?.inactiveStudent,
      },
      {
        type: '',
        value: totalStudents,
      },
    ];

    const InactiveStudentsConfig = {
      appendPadding: 10,
      data: InactiveStudentsData,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.5,
      label: {
        type: 'inner',
        offset: '-50%',
        style: {
          textAlign: 'center',
          fontSize: 1,
          legend: false,
        },

      },
      tooltip: false,
      legend: false,
      statistic: {
        title: true,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: '',
            offsetY: 14,
            fontSize: 15,

          },
          content: totalStudents + '%',
        },
      },
      color: ['#eff629', '#f6eeff',],
    };

    return <Pie {...InactiveStudentsConfig} />
  }, [data])




  const ActiveStudentComp = useMemo(() => {
    var totalStudents = (((data?.totalStudents - data?.inactiveStudent) * 100) / data?.totalStudents).toFixed(0);

    const ActiveStudentsData = [
      {
        type: 'Aktif Öğrenciler',
        value: totalStudents,
      },
      {
        type: '',
        value: (data?.totalStudents - data?.inactiveStudent),
      },
    ];



    const ActiveStudentsConfig = {
      appendPadding: 10,
      data: ActiveStudentsData,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.5,
      label: {
        type: 'inner',
        offset: '-50%',
        style: {
          textAlign: 'center',
          fontSize: 1,
          legend: false,
        },

      },
      tooltip: false,
      legend: false,
      statistic: {
        title: true,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: '',
            offsetY: 14,
            fontSize: 15,

          },
          content: totalStudents + '%',
        },
      },
      color: ['#447ff4', '#f6eeff',],
    };
    return <Pie {...ActiveStudentsConfig} />
  }, [data]);



  //#region  burada teacher Hesaplama yapılmakta.
  const ActiveTeacherComp = useMemo(() => {
    var activeTeachers = ((data?.activeTeachers * 100) / data?.totalTeachers).toFixed(0);
    const ActiveTeachersData = [
      {
        type: 'Aktif Eğitmenler',
        value: data?.totalTeachers,
      },
      {
        type: '',
        value: (data?.totalTeachers - data?.activeTeachers),
      },
    ];

    const ActiveTeachersConfig = {
      appendPadding: 10,
      data: ActiveTeachersData,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.5,
      label: {
        type: 'inner',
        offset: '-50%',
        style: {
          textAlign: 'center',
          fontSize: 1,
          legend: false,
        },

      },
      tooltip: false,
      legend: false,
      statistic: {
        title: true,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: '',
            offsetY: 14,
            fontSize: 15,

          },
          content: activeTeachers + '%',
        },
      },
      color: ['#fe69b5', '#f6eeff',],
    };

    return <Pie {...ActiveTeachersConfig} />
  }, [data])

  //#endregion





  const PaymentRequestData = [
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-PaymentRequests-Text">  Mehmet Ali KARA</p>
      </div>,
      amount: '100₺',
    },


  ];
  const PaymentRequestLimitedData = lastPaymentRequestData?.slice(0, 8);
  const PaymentRequestColumns = [
    {
      title: '',
      dataIndex: 'packageIconUrl',
      render: (text, data) => <img src={text} alt="" className="Dashboard-RightArea-Lists-RecentlySoldPackages-Icon" />,
    },
    {
      title: '',
      dataIndex: '',
      render: (text, data) => {
        return <div className="RecentlySoldPackages">
          <p className="Dashboard-RightArea-Lists-PaymentRequests-Text"> {text.name} {text.surname}</p>
        </div>
      }
    },
    {
      title: '',
      dataIndex: '',
      render: (text, data) => {
        return <p className="Dashboard-RightArea-Lists-PaymentRequests-Price"> {text.packagePrice}₺</p>

      }
    },
  ];

  const SellPacketData = [
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text">  Mehmet Ali KARA</p>
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text">  Mehmet Ali KARA</p>
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text">  Mehmet Ali KARA</p>
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text">  Mehmet Ali KARA</p>
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text">  Mehmet Ali KARA</p>
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text">  Mehmet Ali KARA</p>
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text">  Mehmet Ali KARA</p>
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text">  Mehmet Ali KARA</p>
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text">  Mehmet Ali KARA</p>
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text">  Mehmet Ali KARA</p>
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },

  ];
  const SellPacketLimitedData = purchasedData?.slice(0, 8);
  const SellPacketColumns = [
    {
      title: '',
      dataIndex: 'packageIconUrl',
      render: (text, data) => <img src={text} alt="" className="Dashboard-RightArea-Lists-RecentlySoldPackages-Icon" />,
    },
    {
      title: '',
      dataIndex: '',
      render: (text, data) => {
        return <div className="RecentlySoldPackages">
          <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Text"> {text.name} {text.surname}</p>
          <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">{text.packageDescription}</p>
        </div>
      }
    },
    // {
    //   title: '',
    //   dataIndex: 'packageDescription',
    // },
    {
      title: '',
      dataIndex: '',
      render: (text, data) => {
        return <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Price">{text.packagePrice}₺</p>
      }

    },

  ];

  const StudentTeacherGraphicData = [
    {
      day: 'Pazartesi',
      student: 3,
      teacher: 10,
    },
    {
      day: 'Salı',
      student: 4,
      teacher: 4,
    },
    {
      day: 'Çarşamba',
      student: 3.5,
      teacher: 5,
    },
    {
      day: 'Perşembe',
      student: 5,
      teacher: 5,
    },
    {
      day: 'Cuma',
      student: 4.9,
      teacher: 4.9,
    },
    {
      day: 'Cumartesi',
      student: 6,
      teacher: 35,
    },
    {
      day: 'Pazar',
      student: 7,
      teacher: 7,
    },

  ];

  const StudentTeacherGraphicComp = useMemo(() => {
    if (teacherStundetCheckData) {

      var StudentTeacherGraphicConfig = {
        data: teacherStundetCheckData.dataList,
        xField: 'date',
        yField: 'value',
        seriesField: 'category',
        color: ["#447ff4", "#fe69b5"],
        smooth: true,
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
          },
        },
        animation: {
          appear: {
            animation: 'path-in',
            duration: 2000,
          },
        },

        legend: false,
        lineStyle: {
          lineWidth: 10
        },



      };

      return <Line {...StudentTeacherGraphicConfig} />
    }

  }, [teacherStundetCheckData])

  const questinosCheckDataMemo = useMemo(() => {
    if (questinosCheckData) {
      const QuestionsConfig = {
        data: questinosCheckData?.dailyStats,
        xField: 'date',
        yField: 'count',
        color: ["#447ff4"],
        smooth: true,
        // @TODO 后续会换一种动画方式
        animation: {
          appear: {
            animation: 'path-in',
            duration: 2000,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
          },
        },

        legend: false,
        lineStyle: {
          lineWidth: 10,
          stroke: "#f4f444",
        },
        meta: {
          count: {
            alias: 'Günlük Soru Dağılımı',
          },
        },
      };

      return <Line {...QuestionsConfig} />
    }
  }, [questinosCheckData])





  return (
    <div className="Dashboard">
      <div className="Dashboard-LeftArea">
        <div className="Dashboard-LeftArea-DashboardCard">
          <div className="Dashboard-LeftArea-DashboardCard-Style">

            {
              CardData?.map((item, i) => {

                return <LeftAreaCard item={item} key={i} index={i} />
              }
              )
            }

          </div>
        </div>
        <div className="Dashboard-LeftArea-ActiveUsers">
          <p className="Dashboard-LeftArea-ActiveUsers-Title">
            Aktif Kullanıcılar
          </p>
          <div className="Dashboard-LeftArea-ActiveUsers-GraficArea">
            <div className="Dashboard-LeftArea-ActiveUsers-GraficArea-ActiveStudents-Grafic">
              {ActiveStudentComp}
              <p className="Dashboard-LeftArea-ActiveUsers-GraficArea-ActiveStudents-Explanation">
                Aktif Öğrenciler
              </p>
            </div>

            <div className="Dashboard-LeftArea-ActiveUsers-GraficArea-InactiveStudents-Grafic">
              {InactiveStudentComp}
              <p className="Dashboard-LeftArea-ActiveUsers-GraficArea-InactiveStudents-Explanation">
                İnaktif Öğrenciler
              </p>
            </div>

            <div className="Dashboard-LeftArea-ActiveUsers-GraficArea-ActiveTeachers-Grafic">
              {ActiveTeacherComp}
              <p className="Dashboard-LeftArea-ActiveUsers-GraficArea-ActiveTeachers-Explanation">
                Aktif Eğitmenler
              </p>
            </div>

          </div>
        </div>
        <div className="Dashboard-LeftArea-QuestionsAsked">
          <p className="Dashboard-LeftArea-QuestionsAsked-Title">
            Sorulan Sorular</p>
          <div className="Dashboard-RightArea-Graphic-Information-selected">
            <button onClick={() => setQuestionsCheck((prev) => !prev)} className={"Dashboard-RightArea-Graphic-Information-selected-button " + (questionsCheck ? "" : "active")}>{!questionsCheck ? <FaRegCircleCheck /> : <FaRegCircle />} <span>Aylık</span></button>
            <button onClick={() => setQuestionsCheck((prev) => !prev)} className={"Dashboard-RightArea-Graphic-Information-selected-button " + (questionsCheck ? "active" : "")}>{questionsCheck ? <FaRegCircleCheck /> : <FaRegCircle />} <span>Haftalık</span></button></div>

          <div className="Dashboard-LeftArea-QuestionsAsked-Information" >

            <div className="Dashboard-LeftArea-QuestionsAsked-Information-Student" >
              <img src={process.env.PUBLIC_URL + "/Assets/images/UpArrowIcon.svg"} alt="" style={{ transform: questinosCheckData?.previousQuestion > 0 ? "rotate(0deg)" : "rotate(180deg)" }} className="Dashboard-LeftArea-QuestionsAsked-Information-Student-Icon" />
              <div className="Dashboard-LeftArea-QuestionsAsked-Information-Student-Text">

                <div className="Dashboard-LeftArea-QuestionsAsked-Information-Student-Text-Title">Sorular</div>
                <div className="Dashboard-LeftArea-QuestionsAsked-Information-Student-Text-Number">{questinosCheckData?.previousQuestion}</div>
              </div>
            </div>
          </div>

          {questinosCheckDataMemo}
        </div>



      </div>

      <div className="Dashboard-RightArea">
        <div className="Dashboard-RightArea-Graphic">
          <div className="Dashboard-RightArea-Graphic-Information" >
            <div className="Dashboard-RightArea-Graphic-Information-selected">
              <button onClick={() => setTeacherStundetCheck((prev) => !prev)} className={"Dashboard-RightArea-Graphic-Information-selected-button " + (teacherStundetCheck ? "" : "active")}>{!teacherStundetCheck ? <FaRegCircleCheck /> : <FaRegCircle />} <span>Aylık</span></button>
              <button onClick={() => setTeacherStundetCheck((prev) => !prev)} className={"Dashboard-RightArea-Graphic-Information-selected-button " + (teacherStundetCheck ? "active" : "")}>{teacherStundetCheck ? <FaRegCircleCheck /> : <FaRegCircle />} <span>Haftalık</span></button></div>
            <div className="Dashboard-RightArea-Graphic-Information-Student" >
              <img src={process.env.PUBLIC_URL + "/Assets/images/UpArrowIcon.svg"} alt="" className="Dashboard-RightArea-Graphic-Information-Student-Icon" style={{ transform: teacherStundetCheckData?.previousStudent > 0 ? "rotate(0deg)" : "rotate(180deg)" }} />
              <div className="Dashboard-RightArea-Graphic-Information-Student-Text">

                <div className="Dashboard-RightArea-Graphic-Information-Student-Text-Title">Öğrenci</div>
                <div className="Dashboard-RightArea-Graphic-Information-Student-Text-Number">{teacherStundetCheckData?.previousStudent}</div>
              </div>
            </div>


            <div className="Dashboard-RightArea-Graphic-Information-Teacher" >
              <img src={process.env.PUBLIC_URL + "/Assets/images/UpArrowIcon.svg"} alt="" className="Dashboard-RightArea-Graphic-Information-Teacher-Icon" style={{ transform: teacherStundetCheckData?.previousTeacher > 0 ? "rotate(0deg)" : "rotate(180deg)" }} />
              <div className="Dashboard-RightArea-Graphic-Information-Teacher-Text">

                <div className="Dashboard-RightArea-Graphic-Information-Teacher-Text-Title">Eğitmen</div>
                <div className="Dashboard-RightArea-Graphic-Information-Teacher-Text-Number">{teacherStundetCheckData?.previousTeacher}</div>
              </div>
            </div>

          </div>
          {StudentTeacherGraphicComp}

        </div>
        <div className="Dashboard-RightArea-Lists">

          <div className="Dashboard-RightArea-Lists-PaymentRequests">
            <div className="Dashboard-RightArea-Lists-PaymentRequests-Title">
              <p className="Dashboard-RightArea-Lists-PaymentRequests-Title-Text">
                Ödeme Talepleri
              </p>
              <p className="Dashboard-RightArea-Lists-PaymentRequests-Title-Explanation">
                24 saatteki ödeme talepleri
              </p>
            </div>
            <Table
              columns={PaymentRequestColumns}
              dataSource={PaymentRequestLimitedData}
              pagination={false} //
            />
            <div className="Dashboard-RightArea-Lists-PaymentRequests-MoreArea">
              <Link to="/PaymentRequests" className="Dashboard-RightArea-Lists-PaymentRequests-MoreArea-More" >
                <span className="Dashboard-RightArea-Lists-PaymentRequests-MoreArea-More-Text">250 tane daha</span>
              </Link>
            </div>

          </div>
          <div className="Dashboard-RightArea-Lists-RecentlySoldPackages">
            <div className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title">
              <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Text">
                Son Satılan Paketler
              </p>
              <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation">
                24 saatte satılan paketler
              </p>
            </div>
            <Table
              columns={SellPacketColumns}
              dataSource={SellPacketLimitedData}
              pagination={false} //
            />
            <div className="Dashboard-RightArea-Lists-RecentlySoldPackages-MoreArea">
              <Link to="/sellpacket" className="Dashboard-RightArea-Lists-RecentlySoldPackages-MoreArea-More" >
                <span className="Dashboard-RightArea-Lists-RecentlySoldPackages-MoreArea-More-Text">250 tane daha</span>
              </Link>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
};

export default Dashboard;
