import baseService from "./baseService";

export default class Profiles extends baseService {
    constructor() {
        super();
        this.schemaName = "api";
    }

    userProfile(params) {
        return this.getObject(this.schemaName, "userProfile/" + params)
    }

    teacherProfile(params) {
        return this.getObject(this.schemaName, "teacherProfile")
    }
    activateUserAccount(params) {
        return this.postObject(this.schemaName, "activateUserAccount", params)
    }
}