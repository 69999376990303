import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import './reportedsolutions.scss';
import { GetWord } from "../../../../utils";
import { Table } from "antd";
import Service from "../../../../Service";
import moment from "moment";

const ReportedSolutions = () => {
  const [pageName, setPageName] = useOutletContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userid = (searchParams.get('userid'));
  const teacherid = (searchParams.get('teacherid'));
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const getReportedSolutionsData = useCallback(() => {
    const service = new Service();

    if (userid) {
      service.AdminService.getUserReportedSolutions(userid).then((res) => {
        setData(res);
      })
    }
    else if (teacherid) {
      service.AdminService.getTeacherReportedSolutions(teacherid).then((res) => {
        setData(res);
      })
    }
    else {
      service.AdminService.getReportedSolutions("offset=" + currentPage + "&limit=" + pageSize).then((res) => {
        setData(res);
      })
    }

  }, [currentPage, pageSize, teacherid, userid])

  useEffect(() => {
    getReportedSolutionsData();
  }, [getReportedSolutionsData])

  setPageName(GetWord("Raporlanan Çözümler"));

  const ListColumns = [
    {
      title: 'Adı Soyadı',
      dataIndex: '',
      render: (student) => `${student.askerName} ${student.askerSurname}`,
    },
    {
      title: 'Eğitmen Adı',
      dataIndex: '',
      render: (teacher) => `${teacher.teacherName} ${teacher.teacherSurname}`,
    },
    {
      title: 'Soru Fotoğrafı',
      dataIndex: 'image',
      render: (text, data) => <img className="ReportedSolutions-QuestionPhoto" src={text} alt="" />,
    },
    {
      title: 'Çözüm Fotoğrafı',
      dataIndex: 'image',
      render: (text, data) => <img className="ReportedSolutions-QuestionPhoto" src={text} alt="" />,
    },

    {
      title: 'Rapor Sebebi',
      dataIndex: 'rejectReason',
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      render: (text) => (<span>{moment(text).format("L")}</span>)
    },
    {
      title: 'İşlem',
      dataIndex: 'button',
      render: (text, data) => {
        return <div className="ReportedSolutions-Button">
          <div className="ReportedSolutions-Button-SeeDelete" >
            <button className="ReportedSolutions-Button-SeeDelete-SeeQuestion" onClick={() => navigate('/allquestion/details/' + data.id)} style={{ cursor: 'pointer' }}><span className="ReportedSolutions-Button-Text">Soruyu Gör</span> </button>
            <button className="ReportedSolutions-Button-SeeDelete-Delete" onClick={<ReportedSolutions />} style={{ cursor: 'pointer' }}><span className="ReportedSolutions-Button-Text">Sil</span></button>
          </div>
          <div className="ReportedSolutions-Button-PriceRefund" >
            <button className="ReportedSolutions-Button-PriceRefund-FullPrice" onClick={<ReportedSolutions />} style={{ cursor: 'pointer' }}><span className="ReportedSolutions-Button-Text">Tam Ücret</span> </button>
            <button className="ReportedSolutions-Button-PriceRefund-QuestionRightRefund" onClick={<ReportedSolutions />} style={{ cursor: 'pointer' }}><span className="ReportedSolutions-Button-Text">Soru Hakkı İade</span></button>
          </div>
        </div>
      },
    },
  ];

  const onChangeTable = useCallback((e) => {
    setCurrentPage(e.current - 1);
    setPageSize(e.pageSize)
  }, [])

  return (
    <div>
      <div className="ReportedSolutions-All">
        <div></div>
        <Link className="ReportedSolutions-All-Button" to='/reportedsolutions' style={{ cursor: 'pointer' }}>
          <span className="ReportedSolutions-All-Button-Text">Tümünü Gör</span>
        </Link>


      </div>
      <div>

        <Table
          onChange={onChangeTable}
          pagination={{ total: data?.totalCount }}
          scroll={{ x: 'fit-content' }}
          columns={ListColumns}
          dataSource={data?.data}
        />
      </div>
    </div>


  );
};

export default ReportedSolutions;
