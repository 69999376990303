import React from "react";
import './TestSubjects.scss'
import { Link, NavLink, useOutletContext } from "react-router-dom";
import { GetWord } from "../../../../utils";
import { Table } from "antd";
import AddCard from "./AddCard/AddCard";

const TestSubject = () => {
  const [pageName, setPageName] = useOutletContext();
  setPageName(GetWord("Test/Konu/Bilgi Kartı Ekle"));



  const ListColumns = [
    {
      title: 'Eğitmen Adı',
      dataIndex: 'teacherName',

    },
    {
      title: 'İş',
      dataIndex: 'job',
    },
    {
      title: 'Tutar',
      dataIndex: 'amount',
    },
    {
      title: 'Tarih',
      dataIndex: 'date',
    },
    {
      title: 'İşlem',
      dataIndex: 'button',
    },
  ];

  const ListData = [
    {
      teacherName: 'Mehmet Ali KARA',
      job: 'Konu Anlatımı',
      amount: '88.08₺',
      date: '28.12.2022',
      button: <button className="TestSubject-SecondArea-List-DetailButton" onClick={<TestSubject />} style={{ cursor: 'pointer' }}><span className="TestSubject-SecondArea-List-DetailButton-Text" >Detay</span></button>,
    },

  ];


  return (
    <div className="TestSubject">
      <div className="TestSubject-FirstArea">
        <div className="TestSubject-FirstArea-ButtonArea">

          <Link className="TestSubject-FirstArea-ButtonArea-Button-InformationCardAdd" style={{ cursor: 'pointer' }} to="/TestSubject/AddCard">
            <span className="TestSubject-FirstArea-ButtonArea-Button-Text">Bilgi Kartı Ekle</span>
          </Link>

          <Link className="TestSubject-FirstArea-ButtonArea-Button-SubjectCardAdd" style={{ cursor: 'pointer' }} to="/TestSubject/AddSubject">
            <span className="TestSubject-FirstArea-ButtonArea-Button-Text">Konu Anlatımı Ekle</span>
          </Link>

          <Link className="TestSubject-FirstArea-ButtonArea-Button-TestAdd" style={{ cursor: 'pointer' }} to="/TestSubject/AddTest">
            <span className="TestSubject-FirstArea-ButtonArea-Button-Text">Test Ekle</span>
          </Link>

          <Link className="TestSubject-FirstArea-ButtonArea-Button-History" style={{ cursor: 'pointer' }}>
            <span className="TestSubject-FirstArea-ButtonArea-Button-Text">Geçmiş İşlemler</span>
          </Link>
        </div>
      </div>

      <div className="TestSubject-SecondArea-">
        <div className="TestSubject-SecondArea-List">


          <Table
            scroll={{ x: 'fit-content' }}
            columns={ListColumns}
            dataSource={ListData}
          />
        </div>
      </div>



    </div>
  )
}

export default TestSubject
